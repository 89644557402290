import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { addPayment, addNewAccountAddress } from '@ten24/slatwallreactlibrary/actions/'
import { orderPayment, billingAccountAddressSelector } from '@ten24/slatwallreactlibrary/selectors'
import { FulfilmentAddressSelector } from '../Fulfilment/FulfilmentAddressSelector'

const TermPayment = ({ method }) => {
  const dispatch = useDispatch()
  const { purchaseOrderNumber } = useSelector(orderPayment)
  const billingAddress = useSelector(billingAccountAddressSelector)
  const [termOrderNumber, setTermOrderNumber] = useState(purchaseOrderNumber || '')
  const { t } = useTranslation()
  return (
    <>
      <div className="row mb-3">
        <div className="col-sm-12">
          <div className="form-group">
            <label htmlFor="purchaseOrderNumber">{t('frontend.checkout.payment.po.number')}</label>
            <input
              className="form-control"
              type="text"
              id="purchaseOrderNumber"
              value={termOrderNumber}
              onChange={e => {
                e.preventDefault()
                setTermOrderNumber(e.target.value)
              }}
            />
          </div>
        </div>
      </div>
      {termOrderNumber && termOrderNumber.length > 0 && (
        <FulfilmentAddressSelector
          isShipping={false}
          addressTitle={'Billing Address'}
          selectedAccountID={billingAddress}
          onSelect={value => {
            dispatch(
              addPayment({
                accountAddressID: value,
                saveShippingAsBilling: 0,
                newOrderPayment: {
                  purchaseOrderNumber: termOrderNumber,
                  paymentMethod: {
                    paymentMethodID: method,
                  },
                },
              })
            )
          }}
          onSave={values => {
            dispatch(addNewAccountAddress({ ...values }))
          }}
        />
      )}
    </>
  )
}
export { TermPayment }
