import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CartPromoBox, OrderNotes, OrderSummary, PromotionalMessaging, REVIEW, getCurrentStep } from '@ten24/slatwallreactlibrary/components'
import { placeOrder } from '@ten24/slatwallreactlibrary/actions/'
import { useState } from 'react'
import { axios, sdkURL } from '@ten24/slatwallreactlibrary/services'
import { toast } from 'react-toastify'
import { hasBackorderedItem } from '../../selectors/orderSelectors'
// https://www.digitalocean.com/community/tutorials/how-to-create-multistep-forms-with-react-and-semantic-ui
// https://github.com/srdjan/react-multistep/blob/master/react-multistep.js
// https://www.geeksforgeeks.org/how-to-create-multi-step-progress-bar-using-bootstrap/

const CheckoutSideBar = () => {
  const { orderNotes = '', isFetching } = useSelector(state => state.cart)
  const showBackorderedItemFlag = useSelector(hasBackorderedItem)
  const loc = useLocation()
  const path = loc.pathname.split('/').reverse()[0].toLowerCase()
  const currentStep = getCurrentStep(path)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isTaxExemptFlag, setTaxExemptFlag] = useState(false)
  const [multipleShipments, setMultipleShipments] = useState(false)

  const [hasAlternativeShippingAddressFlag, setAlternativeShippingAddressFlag] = useState(false)
  const TaxExemptString = t('frontend.checkout.review.TaxExempt')
  const backorderAgilityMessage = t('frontend.checkout.review.backorder.agility_message')
  const alternativeShippingAddressString = t('frontend.checkout.review.alternativeShippingAddress')

  const addNotesAndPlaceOrder = e => {
    e.preventDefault()
    let newNotes = orderNotes

    if (isTaxExemptFlag) {
      newNotes = newNotes.length > 0 ? `${newNotes}, \n ${TaxExemptString}` : TaxExemptString
    }

    if (multipleShipments) {
      newNotes = newNotes.length > 0 ? `${newNotes}, \n ${backorderAgilityMessage}` : backorderAgilityMessage
    }
    if (hasAlternativeShippingAddressFlag) {
      newNotes = newNotes.length > 0 ? `${newNotes}, \n ${alternativeShippingAddressString}` : alternativeShippingAddressString
    }
    axios({
      method: 'POST',
      withCredentials: true,
      url: `${sdkURL}api/scope/updateOrderNotes`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        orderNotes: newNotes,
      },
    }).then(response => {
      if (response?.status === 200 && response.data) {
        dispatch(placeOrder())
      } else {
        toast.error('Error')
      }
    })
  }

  return (
    <aside className="col-lg-4 pt-4 pt-lg-0">
      <div className="cz-sidebar-static rounded-lg box-shadow-lg ml-lg-auto">
        <PromotionalMessaging />

        <OrderSummary />
        {currentStep.key !== REVIEW && <CartPromoBox />}

        {currentStep.key === REVIEW && (
          <>
            <OrderNotes />
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <div className="custom-control custom-checkbox">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      id="alternativeShippingAddressFlag"
                      value={hasAlternativeShippingAddressFlag}
                      onChange={() => {
                        setAlternativeShippingAddressFlag(!hasAlternativeShippingAddressFlag)
                      }}
                    />
                    <label className="custom-control-label" htmlFor="alternativeShippingAddressFlag">
                      {alternativeShippingAddressString}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <div className="custom-control custom-checkbox">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      id="taxExemptFlag"
                      value={isTaxExemptFlag}
                      onChange={() => {
                        setTaxExemptFlag(!isTaxExemptFlag)
                      }}
                    />
                    <label className="custom-control-label" htmlFor="taxExemptFlag">
                      {TaxExemptString}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {showBackorderedItemFlag && (
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <div className="custom-control custom-checkbox">
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        id="backorderShippment"
                        value={multipleShipments}
                        onChange={() => {
                          setMultipleShipments(!multipleShipments)
                        }}
                      />
                      <label className="custom-control-label" htmlFor="backorderShippment">
                        {t('frontend.checkout.review.backorder.button')}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <button className="btn btn-primary btn-block mt-4" type="submit" disabled={isFetching} onClick={addNotesAndPlaceOrder}>
              {t('frontend.order.complete')}
            </button>
          </>
        )}
      </div>
    </aside>
  )
}

export { CheckoutSideBar }
