import { toast } from 'react-toastify'
import { isImpersonating, getErrorMessage } from '@ten24/slatwallreactlibrary/utils'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { getUser, getWishLists, receiveCart } from '@ten24/slatwallreactlibrary/actions/'
import { SlatwallApiService } from '@ten24/slatwallreactlibrary/services'

const AccountImpersonationBanner = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { firstName, lastName } = useSelector(state => state.userReducer)
  const name = `${firstName} ${lastName}`
  
  if (!isImpersonating()) {
    return null
  }

  const endImpersonation = () => {
    return SlatwallApiService.account.publicEndImpersonation().then(response => {
      if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
      if (response.isSuccess()) {
        toast.success(t('frontend.account.end_impersonation_successful'))
        dispatch(getUser())
        dispatch(receiveCart(response.success().cart))
        dispatch(getWishLists(true))
      } else {
        toast.error(t('frontend.account.end_impersonation_failed'))
      }
    })
  }

  return (
    <nav className="navbar-default top-bar bg-primary text-center">
     <div className="container">
        <p className="py-2 m-0">You are impersonating <b>{name}</b>'s account. To go back to your normal account view, 
          <button
            type="submit"
            className="text-link bg-transparent border-0 text-light"
            onClick={() => {
              endImpersonation()
            }}
           >
            {t('frontend.account.end_impersonation')}
          </button>
        </p>
      </div>
    </nav>
    )
  }

  export { AccountImpersonationBanner }
