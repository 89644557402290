import { ProductPrice, SimpleImage } from '@ten24/slatwallreactlibrary/components'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { updateItemQuantity, removeItem } from '@ten24/slatwallreactlibrary/actions/'
import { useCartLineItem, useFormatCurrency } from '@ten24/slatwallreactlibrary/hooks/'

import { isAuthenticated } from '@ten24/slatwallreactlibrary/utils'
import { getProductRoute, isAccountVerified } from '@ten24/slatwallreactlibrary/selectors'

const CartLineItem = ({ orderItemID, isDisabled = false }) => {
  const [formatCurrency] = useFormatCurrency({})
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { orderItem, listPrice, productRouting, isFetching } = useCartLineItem(orderItemID)
  const productRoute = useSelector(getProductRoute)
  const productLink = `/${productRoute}/${orderItem.sku.product.urlTitle}`
  const isAuthed = isAuthenticated()
  const isVerified = useSelector(isAccountVerified)
  const isBackordered = +orderItem?.sku?.erpQoh < 1 // checks if the remaining QATs is equal to zero , if yes it means it is a backorder item

  return (
    <div className="row border-bottom pb-3 pt-4 mx-1">
      <div className="col-sm-2 col-3 pr-0">
        <Link className="d-inline-block mx-auto image-width" to={productLink}>
          {orderItem.sku.images && orderItem.sku.images.length > 0 && <SimpleImage src={orderItem.sku.images[0]} alt={orderItem.sku.product.productName} />}
        </Link>
      </div>
      <div className="col-9 col-sm-10 col-md-4">
        <span className="product-meta d-block font-size-xs pb-1">{orderItem.sku.product.productSeries}</span>
        <h3 className="product-title font-size-base mb-2">
          <Link
            to={{
              pathname: `/${productRouting}/${orderItem.sku.product.urlTitle}`,
              state: { ...orderItem.sku.product },
            }}
          >
            {orderItem.sku.product.productName}
          </Link>
        </h3>
        <div className="font-size-sm">
          {`${orderItem.sku.product.brand.brandName} `}
          <span className="text-muted mr-2">{orderItem.sku.skuCode}</span>
        </div>
      </div>
      <div className="col-sm-12 col-md-6 mt-2 mt-md-0">
        <div className="row">
          <div className="col-3">
            <div className="product-price">
              <ProductPrice type="cart" salePrice={orderItem.price} listPrice={listPrice} accentSalePrice={false} />
            </div>
          </div>

          {isDisabled && (
            <div className="col-4">
              <p className="mb-0">
                <span className="text-muted font-size-sm">{t('frontend.cart.qty')} </span>
                <span>{orderItem.quantity}</span>
              </p>
            </div>
          )}
          {!isDisabled && (
            <div className="col-4">
              <label className="d-md-none small">{t('frontend.core.quantity')}</label>
              <input
                className="form-control"
                type="number"
                id="quantity4"
                aria-label="quantity"
                defaultValue={orderItem.quantity}
                disabled={isFetching}
                min={orderItem.sku.skuOrderMinimumQuantity}
                onChange={e => {
                  if(e.target.value >= orderItem.sku.skuOrderMinimumQuantity) {
                    dispatch(updateItemQuantity(orderItem.sku.skuID, e.target.value))
                  } else {
                    e.target.value = orderItem.sku.skuOrderMinimumQuantity
                  }
                }}
              />
            </div>
          )}

          <div className="col-3">
            <div className="text-accent">{isAuthed && isVerified ? formatCurrency(orderItem.extendedPriceAfterDiscount) : '__'}</div>
          </div>

          {!isDisabled && (
            <div className="col-1">
              <button
                className="btn btn-link p-0"
                type="button"
                title="remove"
                disabled={isFetching}
                onClick={event => {
                  event.preventDefault()
                  dispatch(removeItem(orderItem.orderItemID))
                }}
              >
                <i className="fal fa-times-circle h5 m-0"></i>
              </button>
            </div>
          )}
        </div>

        {isBackordered && (
          <div className="p-2 border rounded w-100 d-flex mt-2 align-items-center">
            <i className="fal fa-exclamation-circle mr-2"></i>
            <p className="text-sm mb-0">{orderItem.sku.product.brand.brandLeadTime || t('frontend.order.backorder')}</p>
          </div>
        )}
      </div>
    </div>
  )
}
export { CartLineItem }
