import { useFormatDateTime, useFormatCurrency, useSingleAccountOrderDetails } from '@ten24/slatwallreactlibrary/hooks'
import { ShippingAddressDetails, BillingAddressDetails, TermPaymentDetails, GiftCardDetails, CCDetails, PickupLocationDetails } from '@ten24/slatwallreactlibrary/components'
import { useTranslation } from 'react-i18next'
import { CashDetails } from '../../Checkout/Review/CashDetails'

const OrderDetails = ({ orderInfo, orderFulfillments, orderPayments }) => {
  const [formateDate] = useFormatDateTime({})
  const [formatCurrency] = useFormatCurrency({})
  const { t } = useTranslation()
  const { billingAddressDetails, shippingAddressDetails, pickupLocationDetails, termPaymentDetails, creditCardPaymentDetails, paymentMethodType } = useSingleAccountOrderDetails({ orderInfo, orderFulfillments, orderPayments })

  let agilityOrderNumber = ''
  if(orderInfo.remoteID && orderInfo.remoteID.length > 1) agilityOrderNumber = `(${orderInfo.remoteID})`

  return (
    <div className="row align-items-start mb-5 mr-3">
      <div className="col-md-7">
        <div className="row text-sm">
          <div className="col-6 ">
            {paymentMethodType === 'shipping' && <ShippingAddressDetails className="" shippingAddress={shippingAddressDetails} shippingAddressNickname={''} />}
            {paymentMethodType === 'pickup' && <PickupLocationDetails className="" pickupLocation={pickupLocationDetails} />}
            <h3 className="h6">{t('frontend.account.order.datePlaced')}</h3>
            <p>{formateDate(orderInfo.orderOpenDateTime)}</p>
            <h3 className="h6">{t('frontend.order.OrderNo')}</h3>
            <p>{orderPayments != null ? orderPayments.order_orderNumber : orderInfo.orderNumber}{agilityOrderNumber}</p>
          </div>
          <div className="col-6">
            <BillingAddressDetails billingAddressNickname={''} orderPayment={billingAddressDetails} />
            {paymentMethodType === 'cash' && <CashDetails cashPayment={termPaymentDetails} />}
            {paymentMethodType === 'termPayment' && <TermPaymentDetails termPayment={termPaymentDetails} />}
            {paymentMethodType === 'giftCard' && <GiftCardDetails />}
            {paymentMethodType === 'creditCard' && <CCDetails creditCardPayment={creditCardPaymentDetails} />}{' '}
          </div>
        </div>
      </div>

      <div className="col-md-5 order-summary border rounded p-3 text-center">
        <h3 className="h6">{t('frontend.order.summary')}</h3>
        <table className="w-100 text-sm" style={{ lineHeight: '1.5' }}>
          <tbody>
            <tr>
              <td className="text-left"> {t('frontend.order.subtotal')}</td>
              <td className="text-right">{formatCurrency(orderInfo.calculatedSubTotal)}</td>
            </tr>
            <tr>
              <td className="text-left">{t('frontend.order.shipping')}</td>
              <td className="text-right">{formatCurrency(orderInfo.calculatedFulfillmentTotal)}</td>
            </tr>
            <tr>
              <td className="text-left">{t('frontend.order.taxes')}</td>
              <td className="text-right">{formatCurrency(orderInfo.calculatedTaxTotal)}</td>
            </tr>
            <tr>
              <td className="text-left"> {t('frontend.order.discount')}</td>
              <td className="text-right text-success font-weight-bold">- {formatCurrency(orderInfo.calculatedDiscountTotal)}</td>
            </tr>
          </tbody>
        </table>
        <hr className="mb-4 mt-4" />
        <span className="order-total h3">{formatCurrency(orderInfo.calculatedTotal)}</span>
      </div>
    </div>
  )
}

export { OrderDetails }