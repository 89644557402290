import { BreadCrumb, Layout, RelatedProductsSlider, ProductPageHeader, ProductPagePanels, SkuOptions, Spinner } from '@ten24/slatwallreactlibrary/components'
import { useProductDetail } from '@ten24/slatwallreactlibrary/hooks'
import { useGetEntityByUrlTitleAdvanced } from '../../hooks/useAPI'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { disableInteractionSelector, getProductTypeRoute } from '@ten24/slatwallreactlibrary/selectors'
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router'
import { ProductForm, ProductDetails, ProductDetailGallery } from '../../components/ProductDetail'

const ProductDetail = () => {
  let location = useLocation()
  let history = useHistory()

  const productTypeRoute = useSelector(getProductTypeRoute)
  const productTypeBase = useSelector(state => state.configuration.filtering.productTypeBase)
  const cart = useSelector(disableInteractionSelector)
  const { filterSkusBySelectedOptions, calculateAvaliableOptions, calculateAdditionalParamters } = useProductDetail()
  const urlTitle = location.pathname.split('/').reverse()
  let { isFetching, product, productOptions, error, attributeSets, skus } = useGetEntityByUrlTitleAdvanced(urlTitle[0])
  
  // selection is an object of current paramters
  // optionGroupPairs is an array of current paramters key=value
  let params = queryString.parse(location.search, { arrayFormat: 'separator', arrayFormatSeparator: ',' })
  let optionGroupPairs = location.search
    .replace('?', '')
    .split('&')
    .filter(param => param.length)
  if (error.isError || !productOptions) return null
  const selectionToSku = (skus = [], params = []) => {
    const found = skus.filter(sku => {
      return (
        params.filter(code => {
          return sku.slug.includes(code)
        }).length === productOptions.length
      )
    })
    return found.length === 1 ? found[0] : null
  }
  let selectedSKu = selectionToSku(skus, optionGroupPairs)
  if (params?.skuid) {
    // If we have a skuID we need to redirect to codes
    console.log('skuID found, waiting for skus')
    if (!product) return null
    const found = skus?.filter(sku => sku.skuID === params.skuid)
    if (found?.length) {
      console.log('Redirect based on found sku')
      history.replace({
        pathname: location.pathname,
        search: found[0].slug,
      }) 
    }
  }

  if (optionGroupPairs.length === 0 && product.defaultSku_slug) {
    // This check is for no optionGroupPairs passed
    console.log('<------- product.defaultSku_slug', optionGroupPairs, product)
    history.replace({
      pathname: location.pathname,
      search: product.defaultSku_slug,
    })
  }
  //http://localhost:3006/product/demo-product

  const crumbs = product?.data?.breadcrumbs
    ?.map(crumb => {
      return { title: crumb.productTypeName, urlTitle: `/${productTypeRoute}/${crumb.urlTitle}` }
    })
    .filter(crumb => crumb.urlTitle !== `/${productTypeRoute}/${productTypeBase}`)

  const matchingSkus = filterSkusBySelectedOptions(skus, optionGroupPairs)
  let updatedProductOptions = calculateAvaliableOptions(productOptions, params, matchingSkus)
  let updateParams = calculateAdditionalParamters(optionGroupPairs, updatedProductOptions)
  const selectedSkuID = selectedSKu?.skuID ? selectedSKu?.skuID : product?.defaultSku_skuID;
  
  if (updateParams.length) {
    // http://localhost:3006/product/test-product?soccerBallSize=5 ==>  soccerBallColor=green is added
    console.log('Add additional optionGroupPairs because of option matrix')
    history.replace({
      pathname: location.pathname,
      search: [...optionGroupPairs, updateParams].join('&'),
    })
  }
  let isDisabled = isFetching || cart.isFetching || !selectedSKu?.skuID
  
  if (productOptions?.length === 0 && product.skus.length > 0) {
    console.log('This is a product with skus without option groups')

  if (params?.skuid) {
    selectedSKu = skus?.filter(sku => sku.skuID === params.skuid)?.at(0)
  } else {
    selectedSKu = skus?.filter(sku => sku.skuID === product.defaultSku_skuID)?.at(0)
  }
    isDisabled = false
  }
  if (!product?.productID) return <ProductDetailLoading />
  return (
    <Layout>
      <div className="p-0">
        {product?.productID && (
          <ProductPageHeader title={product?.productSeries}>
            <BreadCrumb crumbs={crumbs} />
          </ProductPageHeader>
        )}

        {product?.productID && <Helmet>
          <title>{product?.settings?.productHTMLTitleString}</title>
          <meta name="description" content={product?.settings?.productMetaDescriptionString} />
        </Helmet>}
        <div className="container bg-light box-shadow-lg rounded-lg px-4 py-3 mb-5">
          {!product?.productID && <Spinner />}
          {product?.productID && (
            <div className="px-lg-3">
              <div className="row">
                <ProductDetailGallery urlTitle={urlTitle[0]} skuID={selectedSkuID} imageFile={selectedSKu?.imageFile} />
                {/* <!-- Product details--> */}
                <div className="col-lg-6 pt-0">
                  <div className="product-details pb-3">
                    <ProductDetails sku={selectedSKu} product={product} skuFetching={isFetching} />
                    {!isFetching && !cart.isFetching && skus?.length && <SkuOptions sku={selectedSKu} selection={params} productOptions={updatedProductOptions} skus={skus} />}
                    {selectedSKu && <ProductForm sku={selectedSKu} isDisabled={isDisabled} isLoading={isFetching || cart.isFetching} productUrl={product.urlTitle} />} {/* <!-- Product panels--> */}
                    <ProductPagePanels product={product} attributeSets={attributeSets} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <RelatedProductsSlider productID={product.productID} />
      </div>
    </Layout>
  )
}

const ProductDetailLoading = () => {
  return (
    <Layout>
      <div className="container mt-5">
        <Spinner />
        <div className="d-flex justify-content-end"></div>
        <div className="row">
          <div className="col-sm-6 col-md-4 mb-4 mb-md-0"></div>
          <div className="col-sm-6 col-md-6 offset-md-1">
            <div className="row mb-4">
              <div className="col-md-12"></div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ProductDetail
