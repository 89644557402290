import { Layout, Listing, BrandBanner, BreadCrumb } from '@ten24/slatwallreactlibrary/components'
import { useLocation } from 'react-router'
import { useBrand } from '@ten24/slatwallreactlibrary/hooks'
import { useState } from 'react'
import { useEffect } from 'react'
import ContentLoader from 'react-content-loader'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const PageHeader = ({ title, children, ...props }) => {
  let loc = useLocation()
  const path = loc.pathname.split('/').reverse()[0].toLowerCase()
  const contentStore = useSelector(state => state.content[path]) || {}
  return (
    <div className="page-title-overlap">
      <div className="container d-lg-flex justify-content-between">
        <BreadCrumb {...props} />
        <div className="order-lg-1 pr-lg-4 text-center text-lg-left flex-1">
          {!!title && <h1 className="h3 text-dark mb-0 font-accent">{title || contentStore.title || ''}</h1>}
          {children}
        </div>
      </div>
    </div>
  )
}

const Brand = () => {
  let { pathname } = useLocation()
  const [brandData, setBrandData] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const productTypeLink = productType => {
    return `${productType.productTypeUrlTitlePath.replace('merchandise/', '/products/')}?facet_manufacturer=${pathname.replace('/brand/', '')}`
  }
  const { brandResponse, productTypeData, brandKey, crumbCalculator, subHeading, urlTitle, title, slug } = useBrand()

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true)
      setBrandData([])
      fetch(`${process.env.REACT_APP_DELTA_API_URL}/api/v1/public/search/brand/${pathname.replace('/brand/', '')}`)
        .then(resp => resp.json())
        .then(data => {
          Promise.all(
            data.productTypeUrlTitlePaths.map(async productType => {
              return fetch(`${process.env.REACT_APP_DELTA_STORE_URL}/public/ultracommerce/product-type/transform/byUrlTitle/merchandise/${productType.productTypeUrlTitlePath}`)
                .then(resp => resp.json())
                .then(data => ({
                  error: false,
                  data,
                }))
                .catch(e => ({
                  error: true,
                  data: null,
                }))
            })
          )
            .then(productTypes => {
              setBrandData(
                productTypes
                  .filter(productType => !productType.error)
                  .map((productType, idx) => ({
                    ...productType.data,
                    productCount: data.productTypeUrlTitlePaths[idx].productCount,
                  }))
              )
            })
            .catch(e => {
              setIsFetching(false)
            })
            .finally(() => {
              setIsFetching(false)
            })
        })
        .catch(e => {
          setIsFetching(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <Layout classNameList="page-overlap-none">
      {productTypeData?.childProductTypes?.length === 0 && <Listing preFilter={{ facet_brandUrlTitle: slug, facet_productTypeUrlTitle: productTypeData.urlTitle }} hide={['productType', 'brands']} />}
      <div className="container bg-light box-shadow-lg rounded-lg p-5 mt-4">
        {productTypeData && (
          <>
            <PageHeader includeHome={true} brand={brandKey && [{ title, urlTitle }]} crumbs={crumbCalculator()}>
              <BrandBanner subHeading={subHeading} brandName={brandResponse?.data[0]?.brandName} images={brandResponse?.data[0]?.images} brandDescription={brandResponse?.data[0]?.brandDescription} />
            </PageHeader>
            {isFetching && (
              <div className="brand-list mt-3">
                <ContentLoader viewBox="0 0 600 80">
                  <rect x="0" y="0" rx="8" ry="8" width="1200" height="80" />
                </ContentLoader>
                <ContentLoader viewBox="0 0 600 80">
                  <rect x="0" y="0" rx="8" ry="8" width="1200" height="80" />
                </ContentLoader>
                <ContentLoader viewBox="0 0 600 80">
                  <rect x="0" y="0" rx="8" ry="8" width="1200" height="80" />
                </ContentLoader>
                <ContentLoader viewBox="0 0 600 80">
                  <rect x="0" y="0" rx="8" ry="8" width="1200" height="80" />
                </ContentLoader>
              </div>
            )}
          </>
        )}
        {!isFetching && (
          <>
            <div className="brand-list">
              {brandData.map(productType => {
                return (
                  <Link to={productTypeLink(productType)} className="brand-list-item" key={productType.productTypeID}>
                    <h6>
                      {productType.productTypeName}
                      <span>
                        {productType.productCount} Product{productType.productCount > 1 ? 's' : ''}
                      </span>
                    </h6>
                    <p className="product-type-name-path">{productType.productTypeNamePath}</p>
                  </Link>
                )
              })}
            </div>
            {brandData && brandData.length === 0 && (
              <div className="alert alert-info p-4 mt-4 mb-4 text-center">
                We currently have no products avalable, please check back later.
              </div>
            )}
          </>
        )}
      </div>
    </Layout>
  )
}

export default Brand
