import { createSelector } from 'reselect'
export const getRoutes = state => state.configuration.router
export const getBlogRoute = state => state.configuration?.blog?.url
export const getProductRoute = createSelector(getRoutes, routes => {
  return routes
    .map(route => {
      return route.URLKeyType === 'Product' ? route.URLKey : null
    })
    .filter(item => {
      return item
    })?.[0]
})
export const getCategoryRoute = createSelector(getRoutes, routes => {
  return routes
    .map(route => {
      return route.URLKeyType === 'Category' ? route.URLKey : null
    })
    .filter(item => {
      return item
    })?.[0]
})