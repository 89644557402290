import { useEffect, useState } from 'react'
import { sdkURL, axios } from '@ten24/slatwallreactlibrary/services'
import { isAuthenticated } from '@ten24/slatwallreactlibrary/utils'

const useLivePrice = sku => {
  let [isFetching, setFetching] = useState(true)
  const [livePrice, setLivePrice] = useState({ livePriceFlag: false, skuPrice: sku?.salePrice, skuCode: sku.skuCode })
  // let [error, setError] = useState({ isError: false, message: '' })
  const authenticated = isAuthenticated()
  useEffect(() => {
    let source = axios.CancelToken.source()
    if (!authenticated) {
      setFetching(false)
      return
    }
    // console.log('Calling Live Price', sku.remoteID)
    axios({
      method: 'POST',
      withCredentials: true,
      url: `${sdkURL}api/scope/getSkuPriceByQuantity`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: { remoteIDs: `${sku.remoteID}` },
    })
      .then(response => {
        // console.log('Retrived Live Price', response)
        if (response.status === 200) {
          return response?.data?.data[0]
        }
        // setError({ isError: true, message: 'Failed to get Live Price' })
        console.log('Failed to get Live Price')
        return 0
      })
      .then(response => {
        setLivePrice(response)
        setFetching(false)
      })
      .catch(() => {
        // setError({ isError: true, message: 'Failed to get Live Price' })
        setFetching(false)
      })
    return () => {
      source.cancel()
    }
  }, [sku.remoteID, authenticated])
  return { isFetchingLivePrice: isFetching, salePrice: livePrice?.skuPrice || sku?.salePrice, validForPurchase: livePrice?.livePriceFlag || !authenticated }
}
export { useLivePrice }
