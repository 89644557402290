import { SlatwallCMSService } from '../services'

export const REQUEST_CONTENT = 'REQUEST_CONTENT'
export const RECEIVE_CONTENT = 'RECEIVE_CONTENT'
export const RECEIVE_COUNTRIES = 'RECEIVE_COUNTRIES'
export const RECEIVE_STATE_CODES = 'RECEIVE_STATE_CODES'
export const REQUEST_CONTENT_SILENTLY = 'REQUEST_CONTENT_SILENTLY'
export const RECEIVE_CONTENT_SILENTLY = 'RECEIVE_CONTENT_SILENTLY'
export const EVICT_ALL_PAGES = 'EVICT_ALL_PAGES'

export const requestContent = () => {
  return {
    type: REQUEST_CONTENT,
  }
}
export const requestContentSiltently = () => {
  return {
    type: REQUEST_CONTENT_SILENTLY,
  }
}
export const receiveContentSiltently = content => {
  return {
    type: RECEIVE_CONTENT_SILENTLY,
    content,
  }
}
export const receiveContent = content => {
  return {
    type: RECEIVE_CONTENT,
    content,
  }
}

export const getPageContent = (content = {}, slug = '') => {
  return async (dispatch, getState) => {
    if (getState().content[slug] || slug === 'product' || slug === 'blog' || slug === 'articles') {
      return new Promise((resolve, reject) => resolve({}))
    }
    dispatch(requestContent())
    const { cmsProvider } = getState().configuration
    if (cmsProvider === 'slatwallCMS') {
      const payload = { 'f:activeFlag': true, 'p:show': 250, includeImages: true, includeSettings: true, ...content }
      SlatwallCMSService.getEntryBySlug(payload, slug).then(({ hydrated }) => {
        dispatch(receiveContent({ hydrated }))
        return hydrated
      })
    }
    return new Promise((resolve, reject) => resolve({}))
  }
}

export const getContentByType = (content = {}, type = 'page', slug = '') => {
  return async (dispatch, getState) => {
    if (getState().content[slug]) {
      return new Promise((resolve, reject) => resolve({}))
    }
    if (type === 'page') dispatch(requestContent())
    const { cmsProvider } = getState().configuration
    if (cmsProvider === 'slatwallCMS') {
      const payload = { 'f:activeFlag': true, 'p:show': 250, ...content }
      SlatwallCMSService.getEntryBySlugAndType(payload, slug, type).then(({ hydrated }) => {
        if (type === 'page') dispatch(receiveContent(hydrated))
        if (type !== 'page') dispatch(receiveContentSiltently(hydrated))
        return hydrated
      })
    }
    return new Promise((resolve, reject) => resolve({}))
  }
}