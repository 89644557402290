import { Helmet } from 'react-helmet'
import { Listing } from '../../components/Listing/Listing'
import { Layout, PageHeader } from '@ten24/slatwallreactlibrary/components'
import queryString from 'query-string'
import { useLocation } from 'react-use'
const Search = () => {
  const loc = useLocation()
  let params = queryString.parse(loc.search, { arrayFormat: 'separator', arrayFormatSeparator: ',' })
  return (
    <Layout>
      <Helmet title={`Search - ${params['keyword']}`} />
      <PageHeader />
      <Listing />
    </Layout>
  )
}
export default Search
