const ListingToolBar = ({ sorting, orderBy, setSort }) => {
  return (
    <div className="d-flex justify-content-between justify-md-content-between align-items-start pt-2 pb-4 pb-sm-5">
      <button className="cz-handheld-toolbar-item p-2 bg-white border rounded d-lg-none" type="button" data-toggle="collapse" data-target="#shop-sidebar" id="sidebar" aria-expanded="false">
        <span className="cz-handheld-toolbar-icon">
          <i className="fal fa-sliders-h"></i>
        </span>
        <span className="cz-handheld-toolbar-label mt-1 font-weight-bold text-dark">Filters</span>
      </button>
      <div className="d-sm-flex pb-3 align-items-center ml-auto">
        <label className="text-dark opacity-75 text-nowrap mr-2 mb-0 d-none d-sm-block" htmlFor="sorting">
          Sort by:
        </label>
        <select
          className="form-control custom-select"
          id="sorting"
          value={orderBy}
          style={{ minWidth: '150' }}
          onChange={event => {
            setSort(event.target.value)
          }}
        >
          {sorting &&
            sorting.length > 1 &&
            sorting.map(({ name, slug }, index) => {
              return (
                <option key={index} value={slug}>
                  {name}
                </option>
              )
            })}
        </select>
      </div>
    </div>
  )
}

export { ListingToolBar }
