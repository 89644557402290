import { default as preload } from '@ten24/slatwallreactlibrary/src/preload'

const data = {
  ...preload,
  site: { ...preload.site, siteName: 'L And R Sales', siteCode: process.env.REACT_APP_SITE_CODE, settings: { siteShippingDisclaimer: '' } },

  enforceVerifiedAccountFlag: true,
  shopByManufacturer: {
    ...preload.shopByManufacturer,
    slug: '/vendors',
  },
  seo: {
    title: 'L And R Sales',
    titleMeta: '',
  },
  products: {
    fallbackImageCall: false,
    quantityInput: 'text', // [ text|dropdown]
  },
  footer: {
    formLink: 'https://landrsales.us1.list-manage.com/subscribe/post?u=365c216a61857500c9d4c4758&amp;id=359429118a',
  },
  theme: {
    ...preload.theme,
    host: process.env.REACT_APP_HOST_URL,
    primaryColor: '08097B',
  },
  cmsProvider: 'slatwallCMS',
  productSearch: {
    ...preload.productSearch,
    includeResizedImages: false,
  },
  productSearchFilters: {
    facet_brandUrlTitle: '',
    facet_productTypeUrlTitle: '',
    facet_landrplus: '',
    currentPage: '1',
    keyword: ''
  },
  analytics: {
    tagManager: {
      gtmId: 'GTM-MNPPXBV',
    },
    googleAnalytics: {
      id: '',
    },
    reportWebVitals: false,
  },
  forms: {
    contact: '2c918089780830c30178229e4983027b',
  },
}
export default data
