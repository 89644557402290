import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ProductPrice, Button, ProductQuantityInput } from '@ten24/slatwallreactlibrary/components'
import { addToCart } from '@ten24/slatwallreactlibrary/actions'
import { useProductPrice } from '@ten24/slatwallreactlibrary/hooks'
import { isAuthenticated } from '@ten24/slatwallreactlibrary/utils'
import { useLivePrice } from '../../hooks/useLivePrice'
import { isAccountVerified, showPriceForUnverifiedAccounts } from '@ten24/slatwallreactlibrary/selectors'

const ProductForm = ({ sku, isDisabled, isLoading, productUrl }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { isFetchingLivePrice, salePrice, validForPurchase } = useLivePrice(sku)
  const showPriceForUnverifiedAccountsFlag = useSelector(showPriceForUnverifiedAccounts)
  const verifiedAccountFlag = useSelector(isAccountVerified)
  const { showAddToCart } = useProductPrice({ salePrice: salePrice, listPrice: sku?.listPrice })
  const [quantity, setQuantity] = useState(sku.skuOrderMinimumQuantity)
  if(quantity < sku.skuOrderMinimumQuantity) {
    setQuantity(sku.skuOrderMinimumQuantity)
  }
  return (
    <>
      {!isFetchingLivePrice && !validForPurchase && <span>{t('frontend.product.price.missing')}</span>}
      {!isFetchingLivePrice && !!validForPurchase && <ProductPrice salePrice={salePrice} listPrice={sku.listPrice} />}
      {(verifiedAccountFlag || showPriceForUnverifiedAccountsFlag) && (
        <form className="mb-grid-gutter" onSubmit={e => e.preventDefault()}>
          <div className="form-group d-flex align-items-center">
            {isFetchingLivePrice && (
              <>
                <span> </span>
                <i className="far fa fa-spinner fa fa-spin font-size-lg mr-2" />
              </>
            )}
            {showAddToCart && validForPurchase && (
              <>
                <ProductQuantityInput setQuantity={setQuantity} quantity={quantity} sku={sku} />
                <Button
                  disabled={isDisabled}
                  isLoading={isLoading}
                  className="btn btn-primary btn-block"
                  onClick={event => {
                    dispatch(addToCart(sku.skuID, quantity,t('frontend.checkout.item_added_successfully')))
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth',
                    })
                  }}
                >
                  <i className="far fa-shopping-cart font-size-lg mr-2"></i>
                  {t('frontend.product.add_to_cart')}
                </Button>
              </>
            )}
          </div>
          {!isAuthenticated() && (
            <div className="alert alert-warning d-flex flex-column" role="alert">
              {t('frontend.pdp.loginToView')} <Link to={`/my-account?redirect=/product/${productUrl}`}>{t('frontend.account.login')}</Link>
            </div>
          )}
        </form>
      )}
    </>
  )
}

export { ProductForm }
