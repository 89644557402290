import { FacetSearch, FacetHeading } from '@ten24/slatwallreactlibrary/components'
import { useListingFilter } from '../../../hooks/components/Listing/useListingFilter'

const AttributeList = ({ facetKeyName = 'name', facet, filterName, facetKey, updateAttribute, isSelected = false, slug }) => {
  const token = filterName.replace(/\s/g, '') + facet.name.replace(/\s/g, '') + 'input'
  return (
    <li className={`widget-list-item cz-filter-item`}>
      <div className="widget-list-link d-flex justify-content-between align-items-center">
        <span className="cz-filter-item-text">
          <div className="custom-control custom-checkbox">
            <input
              className="custom-control-input"
              type="checkbox"
              checked={isSelected}
              onChange={() => {
                const hashKey = !isSelected ? token : null
                updateAttribute({ name: facet[facetKeyName], filterName: facetKey }, hashKey)
              }}
              id={slug}
            />
            <label className="custom-control-label" htmlFor={slug}>
              {facet.name}
            </label>
          </div>
        </span>
        {facet.count && <span className="font-size-xs text-muted ml-3">{facet.count}</span>}
      </div>
    </li>
  )
}

const FacetFilter = ({ filter, updateAttribute, facetIdentifier = '', facetKey = '' }) => {
  
  const { searchTerm, setSearchTerm, searchResults, appliedFilters } = useListingFilter(filter.options, filter.selectType, facetKey)
 
  if(facetKey === 'facet_landrplus' && filter.options.length < 2) {
    return false
  }
  return (
    <div className="card border-bottom pt-1 pb-2 my-1">
      <FacetHeading name={filter.name} value={facetKey} />
      <div className="collapse show" aria-labelledby={`filer${facetKey}`} id={`filer${facetKey}`} data-parent="#shop-categories">
        <div className="card-body">
          <div className="widget widget-links cz-filter">
            <FacetSearch facetKey={facetKey} searchTerm={searchTerm} search={setSearchTerm} />
            <div className={`widget-list cz-filter-list pt-1  ${facetKey}-list`} style={{ maxHeight: '12rem', overflowY: 'auto' }}>
              {searchResults &&
                searchResults.map(facet => {
                  const isSelected = appliedFilters.includes(facet[facetIdentifier])
                  return <AttributeList facetKeyName={facetIdentifier} filterName={filter.name} isSelected={isSelected} facet={facet} key={facet.id || facet.name} facetKey={facetKey} updateAttribute={updateAttribute} slug={facet.slug} />
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { FacetFilter, AttributeList }
