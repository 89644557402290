import { useDispatch, useSelector } from 'react-redux'
import { addPayment } from '@ten24/slatwallreactlibrary/actions/'
import { FulfilmentAddressSelector } from '../Fulfilment/FulfilmentAddressSelector'
import { addNewAccountAddress } from '@ten24/slatwallreactlibrary/actions'
import { billingAccountAddressSelector } from '@ten24/slatwallreactlibrary/selectors'

const Cash = ({ method }) => {
  const dispatch = useDispatch()
  const billingAddress = useSelector(billingAccountAddressSelector)
  return (
    <>
      <FulfilmentAddressSelector
        isShipping={false}
        addressTitle={'Billing Address'}
        selectedAccountID={billingAddress}
        onSelect={value => {
          dispatch(
            addPayment({
              accountAddressID: value,
              saveShippingAsBilling: 0,
              newOrderPayment: {
                paymentMethod: {
                  paymentMethodID: method,
                },
              },
            })
          )
        }}
        onSave={values => {
          dispatch(addNewAccountAddress({ ...values }))
        }}
      />
    </>
  )
}
export { Cash }
