import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getItemsForDefaultWishList } from '@ten24/slatwallreactlibrary/selectors'

const useAccountFavorites = () => {
  const items = useSelector(getItemsForDefaultWishList)
  const isListItemsLoaded = useSelector(state => state.userReducer.wishList.isListItemsLoaded)
  const [productList, setProductList] = useState([])
  const [currentPage, setPage] = useState(1)
  const [isFetching, setFetching] = useState(true)
  const countToDisplay = 6

  useEffect(() => {
    let isUnmounted = false
    const fetchProductList = async () => {
      setFetching(true)
      const productList = await Promise.all((items || []).map(skuID => fetch(`${process.env.REACT_APP_DELTA_STORE_URL}/public/ultracommerce/product/transform/skus/bySkuID/${skuID}`).then(res => res.json())))
      if (!isUnmounted) {
        setProductList(productList)
        setFetching(false)
      }
    }

    if (isListItemsLoaded) fetchProductList()

    return () => {
      isUnmounted = true
    }
  }, [isListItemsLoaded, items])

  const start = (currentPage - 1) * countToDisplay
  const end = start + countToDisplay
  return { skuList: productList, isFetching, start, end, countToDisplay, setPage }
}
export { useAccountFavorites }
