import { Spinner } from '@ten24/slatwallreactlibrary/components'
import { useUtilities } from '@ten24/slatwallreactlibrary/hooks'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import defaultMissing from '../../assets/images/missing.png'
import defaultMissingBrand from '../../assets/images/missing-brand.png'
import defaultMissingProductType from '../../assets/images/missing-product-type.png'
import defaultMissingProduct from '../../assets/images/missing-product.png'

const DefaultImage = ({ alt = '', style, type, className = '' }) => {
  if (type === 'product') {
    return <img className={className} style={style} src={defaultMissingProduct} alt={alt} />
  } else if (type === 'productType') {
    return <img className={className} style={style} src={defaultMissingProductType} alt={alt} />
  } else if (type === 'brand') {
    return <img className={className} style={style} src={defaultMissingBrand} alt={alt} />
  }
  return <img className={className} style={style} src={defaultMissing} alt={alt} />
}

const SWImage = ({ className = '', customPath, src, alt = '', style = {}, type = 'product', fallbackPath = '' }) => {
  const { host, basePath } = useSelector(state => state.configuration.theme)
  const [retryCount, setRetryCount] = useState(0)
  const [classList, setClassList] = useState(['productImage'])
  const path = customPath ? customPath : basePath

  const onErrorCallback = e => {
    e.preventDefault()
    e.target.onerror = null
    const localRetryCount = retryCount + 1
    const showFallback = fallbackPath.length && localRetryCount === 1 ? 'fallbackImage' : ''
    const showDefault = (fallbackPath.length && localRetryCount > 1) || (!fallbackPath.length && localRetryCount === 1) ? 'defaultImage' : ''
    setRetryCount(localRetryCount)
    setClassList(['productImage', className, showFallback, showDefault])
    if (type === 'product') {
      e.target.src = showFallback ? `${host}${fallbackPath}` : defaultMissingProduct
    } else if (type === 'productType') {
      e.target.src = defaultMissingProductType
    } else if (type === 'brand') {
      e.target.src = defaultMissingBrand
    } else {
      e.target.src = defaultMissing
    }
  }

  if (src) {
    return <img className={classList.join(' ')} src={path ? path + src : host + src} alt={alt} style={style} onError={onErrorCallback} />
  }
  return <DefaultImage className={`${className} defaultImage`} style={style} type={type} />
}

const ProductTypeList = ({ data, onSelect, isFetching = true }) => {
  let { eventHandlerForWSIWYG } = useUtilities()
  return (
    <>
      {isFetching && (
        <div className="container bg-light box-shadow-lg rounded-lg p-5">
          <Spinner />
        </div>
      )}
      {!isFetching && data?.childProductTypes && data.childProductTypes?.length > 0 && (
        <div className="container bg-light box-shadow-lg rounded-lg p-5">
          <div onClick={eventHandlerForWSIWYG} dangerouslySetInnerHTML={{ __html: data?.productTypeDescription }} />
          <div className="row">
            {data?.childProductTypes
              .sort((a, b) => (a.productTypeName > b.productTypeName ? 1 : -1))
              .map(({ productTypeID, productTypeNamePath, productTypeName, imageFile, urlTitle, childProductTypes }) => {
                let customImagePath = ''
                let imageFileName = ''
                let imageFullName = ''
                if (imageFile !== '') {
                  imageFileName = imageFile.split('/').reverse()[0].split('.')
                  imageFullName = imageFileName[0] + '-thumb.' + imageFileName[1]
                  customImagePath = imageFile.split('/').slice(0, -1).join('/') + '/'
                }
                return (
                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-3" key={productTypeID}>
                    <div className="card border-0">
                      <div
                        className="d-block overflow-hidden rounded-lg typelisting-image"
                        onClick={e => {
                          e.preventDefault()
                          onSelect(urlTitle)
                        }}
                      >
                        <SWImage className="d-block w-100" customPath={customImagePath} src={imageFullName} alt={productTypeName} type="productType" />
                      </div>
                      <div className="card-body text-center">
                        <h2 className="h5">
                          <button
                            className="link-button "
                            onClick={e => {
                              e.preventDefault()
                              onSelect(urlTitle)
                            }}
                          >
                            {productTypeName}
                          </button>
                        </h2>
                        <span>{productTypeNamePath}</span>
                        <ul className="list-unstyled font-size-sm mb-0">
                          {childProductTypes &&
                            childProductTypes.length > 0 &&
                            childProductTypes
                              .sort((a, b) => (a.productTypeName > b.productTypeName ? 1 : -1))
                              .map(({ productTypeID, productTypeName, urlTitle }) => {
                                return (
                                  <li key={productTypeID}>
                                    <button
                                      className="link-button nav-link-style d-flex align-items-center justify-content-between text-left"
                                      onClick={e => {
                                        e.preventDefault()
                                        onSelect(urlTitle)
                                      }}
                                    >
                                      <i className="far fa-chevron-circle-right pr-2"></i>
                                      {productTypeName}
                                    </button>
                                  </li>
                                )
                              })}
                        </ul>
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      )}{' '}
    </>
  )
}

export { ProductTypeList }
