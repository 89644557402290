import React, { Suspense, useEffect } from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Loading, SEO, ScrollToTop } from '@ten24/slatwallreactlibrary/components'
import { getConfiguration, getWishLists } from '@ten24/slatwallreactlibrary/actions'
import { NotFound, ErrorFallback, Category, Account, OrderConfirmation, Contact } from '@ten24/slatwallreactlibrary/pages'
import { Checkout, Cart, MyAccount, ProductDetail, ProductType, Search, Specials, Home, ContentPage } from './pages/'
import { Header } from './components/Header/Header'
import logo from './assets/images/logo.svg'
import mobileLogo from './assets/images/logo-mobile.svg'
import { ErrorBoundary } from 'react-error-boundary'
import Manufacturer from './pages/Manufacturer/Manufacturer'
import Brand from './pages/Brand/Brand'
import { useCMSWrapper } from './hooks'

const pageComponents = {
  Home,
  ErrorFallback,
  Contact,
  Checkout,
  Cart,
  MyAccount,
  Search,
  ProductDetail,
  NotFound,
  ContentPage,
  Product: ProductDetail,
  ProductType,
  Category,
  Brand,
  Account,
  Manufacturer,
  OrderConfirmation,
}

//https://itnext.io/react-router-transitions-with-lazy-loading-2faa7a1d24a
export default function App() {
  const routing = useSelector(state => state.configuration.router)
  const shopByManufacturer = useSelector(state => state.configuration.shopByManufacturer)
  const dispatch = useDispatch()
  // eslint-disable-next-line
  const cms = useCMSWrapper()
  const loc = useLocation()
  useEffect(() => {
    dispatch(getConfiguration())
    dispatch(getWishLists())
  }, [dispatch])
  useEffect(() => {
    console.log(`Client Version: ${process.env.REACT_APP_VERSION || 'missing'}`)
    console.log(`Core Version: ${process.env.REACT_APP_CORE_VERSION || 'missing'}`)
    console.log(`SDK Version: ${process.env.REACT_APP_SDK_VERSION || 'missing'}`)
  }, [])
  return (
    <Suspense fallback={<Loading />}>
      <Header logo={logo} mobileLogo={mobileLogo} />
      <ErrorBoundary
        key={loc.pathname}
        FallbackComponent={ErrorFallback}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
        }}
      >
        <ScrollToTop />
        <SEO />
        <Switch>
          <Route path="/404" component={NotFound} />
          <Route path="/Error" component={ErrorFallback} />
          <Route path="/contact" component={Contact} />
          {routing.length &&
            routing.map(({ URLKey, URLKeyType }, index) => {
              return <Route key={index} path={`/${URLKey}/:id`} component={pageComponents[URLKeyType]} />
            })}
          <Route path="/order-confirmation" component={OrderConfirmation} />
          <Route path={shopByManufacturer.slug} component={Manufacturer} />
          <Route path="/search" component={Search} />
          <Route path="/my-account" component={MyAccount} />
          <Route path="/checkout" component={Checkout} />
          <Route path="/checkout/:id" component={Checkout} />
          <Route path="/MyAccount" component={MyAccount} />
          <Route path="/shopping-cart" component={Cart} />
          <Route path="/specials" component={Specials} />
          <Route exact path="/" component={Home} />
          <Route path="" component={ContentPage} />
        </Switch>
      </ErrorBoundary>
    </Suspense>
  )
}
