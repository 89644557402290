import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getBrandRoute, getProductTypeRoute } from '@ten24/slatwallreactlibrary/selectors'
import { getCategoryRoute } from '../../selectors/configurationSelectors'
import { HeartButton } from '@ten24/slatwallreactlibrary/components'
import { useFormatDateTime } from '@ten24/slatwallreactlibrary/hooks'

const ProductDetailHeading = ({ sku, product, total, averageRating }) => {
  const brand = useSelector(getBrandRoute)
  const productType = useSelector(getProductTypeRoute)
  const categoryRoute = useSelector(getCategoryRoute)
  const { t } = useTranslation()
  const [formatDate] = useFormatDateTime()
  return (
    <>
      {!!product.brand_brandName?.trim()?.length && (
        <div className="row">
          <div className="col">
            <strong className="mb-2 text-muted text-uppercase small">{t('frontend.product.brand')}: </strong>

            <Link className="link text-decoration-none" to={`/${brand}/${product.brand_urlTitle}`}>
              {product.brand_brandName}
            </Link>
          </div>
        </div>
      )}

      {!!product.productType_productTypeID?.trim()?.length && (
        <div className="row">
          <div className="col">
            <strong className="mb-2 text-muted text-uppercase small">{t('frontend.product.productType')}: </strong>

            <Link className="link text-decoration-none" to={`/${productType}/${product.productType_urlTitle}`}>
              {product.productType_productTypeName}
            </Link>
          </div>
        </div>
      )}

      {!!product.categories?.length && (
        <div className="row">
          <div className="col category-links">
            <strong className="mb-2 text-muted text-uppercase small">{t('frontend.product.category')}: </strong>
            {product.categories.map(category => (
              <Link key={category.categoryID} className="link text-decoration-none" to={`/${categoryRoute}/${category.urlTitle}`}>
                {category.categoryName}
              </Link>
            ))}
          </div>
        </div>
      )}

      {sku?.eventStartDateTime?.length > 1 && (
        <div className="row">
          <div className="col category-links">
            <i className="bi bi-calendar"></i>
            {` ${formatDate(sku?.eventStartDateTime)} ${sku?.eventEndDateTime ? '- ' + formatDate(sku?.eventEndDateTime) : ''}`}
          </div>
        </div>
      )}

      {sku.locationConfigurations_activeFlag === true && (
        <div className="row">
          <div className="col category-links">
            <i className="bi bi-pin-map"></i>
            {sku.locationConfigurations_locationConfigurationName}
          </div>
        </div>
      )}
    </>
  )
}

const ProductDetails = ({ sku, product, skuFetching }) => {
  const brand = useSelector(getBrandRoute)

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <span className="d-inline-block font-size-sm align-middle px-2 bg-primary text-light"> {product.productClearance === true && ' On Special'}</span>
        {sku && <HeartButton skuID={sku.skuID} className={'btn-wishlist mr-0'} />}
      </div>
      <h2 className="h4 mb-2">{product.productName}</h2>
      <div className="mb-2">
        <span className="text-small text-muted">{`SKU: `}</span>
        {skuFetching && <span><i className="fas fa-spinner fa-spin text-accent mr-1"></i></span>}
        {!skuFetching && sku && <span className="font-weight-normal text-large text-accent mr-1">{sku.skuCode}</span>}
      </div>
      <div className="mb-2">
        <span className="text-small text-muted">{`Brand: `}</span>
        {product && (
          <span className="font-weight-normal text-large  mr-1">
            <Link to={`/${brand}/${product.brand_urlTitle}`}>{product.brand_brandName}</Link>
          </span>
        )}
      </div>
    </>
  )
}
export { ProductDetails, ProductDetailHeading }
