const CashDetails = ({ cashPayment}) => {
  const { paymentMethod } = cashPayment

  return (
    <>
      <p>
        <em>{paymentMethod?.paymentMethodName}</em>
      </p>
    </>
  )
}

export { CashDetails }