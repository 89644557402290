import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Layout, PageHeader } from '@ten24/slatwallreactlibrary/components'
import { Listing } from '../../components/Listing/Listing'
import { Helmet } from 'react-helmet'
import { useState } from 'react'
import { useEffect } from 'react'
import { ProductTypeList } from '../../components/ProductTypeList/ProductTypeList'
import { getProductTypeRoute } from '@ten24/slatwallreactlibrary/selectors'
import { useSelector } from 'react-redux'

const ProductType = () => {
  const { id } = useParams()
  const history = useHistory()
  let { pathname } = useLocation();
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const productTypeRoute = useSelector(getProductTypeRoute);
  const [productTypeData, setProductTypeData] = useState()
  const [isFetching, setIsFetching] = useState(false)
  const productTypeBase = useSelector(state => state.configuration.filtering.productTypeBase)
  
  const crumbCalculator = () => {
    return productTypeData?.ancestors
      ?.map(crumb => {
        return { title: crumb.productTypeName, urlTitle: crumb.urlTitle }
      })
      .filter(crumb => crumb.urlTitle !== productTypeBase)
      .filter(crumb => crumb.urlTitle !== id)
      .map(crumb => {
        return { ...crumb, urlTitle: `/${productTypeRoute}/${crumb.urlTitle}` }
      })
  }

  useEffect(() => {
    if(!isFetching) {
      setIsFetching(true)
      setProductTypeData({})
      fetch(`${process.env.REACT_APP_DELTA_STORE_URL}/public/ultracommerce/product-type/transform/byUrlTitle${pathname.replace('products', 'merchandise').replace('/range/', '')}`).then(resp => resp.json()).then(data => {
        setIsFetching(false)
        setIsError(false)
        setErrorMessage()
        setProductTypeData({
          ...data,
          childProductTypes: data?.children.map(child => ({
            ...child,
            urlTitle: child.productTypeUrlTitlePath.replace('merchandise/', '')
          }))
        })
      }).catch(e => {
        setIsFetching(false)
        setIsError(true)
        setErrorMessage("Error finding our products")
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Layout>
      {isFetching && 
      <Helmet>
          <title>{productTypeData?.settings?.productHTMLTitleString}</title>
          <meta name="description" content={productTypeData?.settings?.productMetaDescriptionString} />
        </Helmet>}
      <PageHeader title={productTypeData?.productTypeName} crumbs={crumbCalculator()} />
      {isError && (
        <div className="container bg-light box-shadow-lg rounded-lg p-5">
          <div className="row">
            <div className="alert alert-info" role="alert">
              {errorMessage}
            </div>
          </div>
        </div>
      )}
      <ProductTypeList
        isFetching={isFetching}
        onSelect={urlTitle => {
          history.push(`/${productTypeRoute}/${urlTitle}`)
        }}
        data={productTypeData}
      />
      {productTypeData?.childProductTypes?.length === 0 && (
        <Listing hide={['productTypeUrlTitle']}>
          <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
            <h5 className="h4 text-dark mb-0 font-accent">{}</h5>
          </div>
        </Listing>
      )}
    </Layout>
  )
}

export default ProductType
