import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { SwRadioSelect } from '@ten24/slatwallreactlibrary/components'
import { getBillToAddresses } from '../../../selectors/userSelectors'

const PaymentAddressSelector = ({ onSelect, onSave, selectedAccountID, addressTitle = 'frontend.account.addresses', isShipping = true }) => {
  const accountAddresses = useSelector(getBillToAddresses)
  const { t } = useTranslation()

  return (
    <>
      <h2 className="h6 pt-1 pb-3 mb-3 border-bottom">{t(addressTitle)}</h2>
      {accountAddresses && (
        <div className="row">
          <div className="col-sm-12">
            <SwRadioSelect
              options={accountAddresses.map(({ accountAddressName, accountAddressID, address: { streetAddress } }) => {
                return { name: `${accountAddressName} - ${streetAddress}`, value: accountAddressID }
              })}
              onChange={value => {
                onSelect(value)
              }}
              customLabel={t('frontend.checkout.receive_option')}
              selectedValue={selectedAccountID}
              displayNew={true}
            />
          </div>
        </div>
      )}
    </>
  )
}

export { PaymentAddressSelector }
