import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { BrandSlider, ContentColumns, Layout } from '@ten24/slatwallreactlibrary/components'
import { Blocks, ListItem } from '../../components'
import { ContentBlock } from '../../components/ContentBlock/ContentBlock'
import { ContentSlider } from '../../components/ContentSlider/ContentSlider'
import { useTranslation } from 'react-i18next'
import { ProductSlider } from '../../components/ProductSlider/ProductSlider'

const Home = () => {
  const { t } = useTranslation()
  const content = useSelector(state => state.content['home'])
  const { home = {} } = useSelector(state => state.content)
  const { contentColumns, slider, brandSlider, listItems, blocks } = home
  return (
    <Layout>
      <Helmet>
        <title>{content?.settings?.contentHTMLTitleString}</title>
        <meta name="description" content={content?.settings?.contentMetaDescriptionString} />
      </Helmet>
      <ContentSlider slider={slider} />
      <ProductSlider
        title={t('frontend.home.featured.heading')}
        params={{
          'f:publishedFlag': 1,
          'f:productFeaturedFlag': 1,
          'f:type': 'product'
        }}
      >
        <p>
          <Link to={`/specials`}>{t('frontend.home.featured.cta')}</Link>
        </p>
      </ProductSlider>
      {contentColumns?.columns?.length > 0 && (
        <ContentColumns title={contentColumns.title}>
          <div className="row justify-content-center">
            {contentColumns.columns.map((column, idx) => {
              return (
                <div key={idx} className={`col-lg-${12 / contentColumns.columns.length} pr-4-lg`}>
                  <ContentBlock {...column} />
                </div>
              )
            })}
          </div>
        </ContentColumns>
      )}

      {brandSlider && brandSlider.length > 0 && <BrandSlider params={{ 'f:brandFeatured': 1 }} />}
      
      {listItems && 
        listItems.map(item => {
        return <ListItem {...item} />
      })}
     {blocks && blocks?.length > 0 && <Blocks blocks={blocks} content={content} />}
    </Layout>
  )
}

export default Home
