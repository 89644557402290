import { ListingGrid } from './ListingGrid'
import { ListingPagination } from '@ten24/slatwallreactlibrary/components'
import { ListingSidebar } from './ListingSidebar'
import { useListing } from '../../hooks/components/Listing/useListing'
import { ListingToolBar } from './ListingToolBar'

const Listing = ({ preFilter, hide = [] }) => {
  const { records, isFetching, potentialFilters, total, totalPages, setSort, updateAttribute, setPage, setKeyword, params, sorting } = useListing(preFilter)

  return (
    <>
      <div className="container pb-5 mb-2 mb-md-4">
        <div className="row">
          <aside className="col-lg-4">
            <ListingSidebar isFetching={isFetching} hide={hide} filtering={potentialFilters} recordsCount={total} keyword={params['keyword']} setKeyword={setKeyword} updateAttribute={updateAttribute} />
          </aside>
          <div className="col-lg-8">
            <ListingToolBar hide={hide} sorting={sorting} {...potentialFilters} orderBy={params['sort']} removeFilter={updateAttribute} setSort={setSort} />
            <ListingGrid isFetching={isFetching} pageRecords={records} />
            <ListingPagination recordsCount={total} currentPage={params['currentPage']} totalPages={totalPages} setPage={setPage} />
          </div>
        </div>
      </div>
    </>
  )
}

export { Listing }
