import { useState, useEffect, useRef } from 'react'
import { SlatwallApiService, axios } from '@ten24/slatwallreactlibrary/services'
import { useHistory, useLocation } from 'react-router'
import { toast } from 'react-toastify'
import { getErrorMessage } from '@ten24/slatwallreactlibrary/utils'
import { useSelector } from 'react-redux'

const headers = {}

/**
 * Overwrite the normal fetch to use cached version in bucket
 */
export const fetchFromProductCache = async (payload, headers, source) => {
  if (payload.entityName === 'product') {
    // Include hostname when on localhost (so that you still get data)
    let hostname = window.location.hostname === 'localhost' ? 'https://landrsales.ultracommerce-dev.co' : ''
    try {
      let resp = await fetch(`${hostname}/cache/product/${payload.urlTitle}.json`)
      let data = await resp.json()
      console.info(`Cache HIT for ${payload.urlTitle}`)
      return {
        isSuccess: () => true,
        success: () => ({ data: data }),
      }
    } catch (e) {
      payload.includeSkus = true
      console.info(`Cache MISS for ${payload.urlTitle}`)
    }
  }
  return SlatwallApiService.general.getEntity(payload, headers, source)
}

// Fetch from delta
export const fetchFromDelta = async (payload, headers, source) => {
  if (payload.entityName === 'product' || payload.entityName === 'sku') {
    // Include hostname when on localhost (so that you still get data)
    try {
      let resp = await fetch(`${process.env.REACT_APP_DELTA_STORE_URL}/public/ultracommerce/product/transform/byUrlTitle/${payload.urlTitle}`)
      let data = await resp.json()
      console.info(`Cache HIT for ${payload.urlTitle}`)
      return {
        isSuccess: () => true,
        success: () => ({ data: data }),
      }
    } catch (e) {
      payload.includeSkus = true
      console.info(`Cache MISS for ${payload.urlTitle}`)
    }
  }
  return SlatwallApiService.general.getEntity(payload, headers, source)
}

export const useGetEntity = () => {
  let [request, setRequest] = useState({ isFetching: false, isLoaded: false, makeRequest: false, data: [], error: '', params: {}, entity: '' })
  useEffect(() => {
    let source = axios.CancelToken.source()
    if (request.makeRequest) {
      const payload = { ...request.params, entityName: request.entity }
      SlatwallApiService.general.getEntity(payload, headers, source).then(response => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
        if (response.isSuccess() && response.success().data && response.success().data.pageRecords) {
          setRequest({ data: response.success().data.pageRecords, attributeSets: response.success().attributeSets, isFetching: false, isLoaded: true, makeRequest: false, params: {} })
        } else if (response.isSuccess() && response.success().data && response.success().data[request.entity]) {
          setRequest({ data: response.success().data[request.entity], attributeSets: response.success().attributeSets, isFetching: false, isLoaded: true, makeRequest: false, params: {} })
        } else {
          setRequest({ data: [], attributeSets: [], isFetching: false, makeRequest: false, isLoaded: true, params: {}, error: 'Something was wrong' })
        }
      })
    }
    return () => {
      source.cancel()
    }
  }, [request, setRequest])

  return [request, setRequest]
}

export const useGetEntityWithPagination = (entity, currentPage, maxCount, orderBy, filters = '{}') => {
  let [isFetching, setFetching] = useState(true)
  let [data, setData] = useState({ pageRecords: [], totalRecords: 0, totalPages: 1 })
  let [error, setError] = useState({ isError: false, message: '' })
  useEffect(() => {
    let source = axios.CancelToken.source()
    const parsedFilters = JSON.parse(filters)
    const payload = { 'f:activeFlag': 1, orderBy, 'p:current': currentPage, 'P:Show': maxCount, entityName: entity, ...parsedFilters }
    setFetching(true)

    SlatwallApiService.general.getEntity(payload, headers, source).then(response => {
      if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) setError({ isError: true, message: getErrorMessage(response.success().errors) })
      if (response.isSuccess() && response.success().data && response.success().data.pageRecords) {
        setData(response.success().data)
      } else if (response.isSuccess() && response.success().data && response.success().data[entity]) {
        setData(response.success().data[entity])
      } else {
        setData([])
        setError({ isError: true, message: 'Something was wrong' })
      }
      setFetching(false)
    })

    return () => {
      source.cancel()
    }
  }, [entity, currentPage, maxCount, orderBy, filters])
  return { isFetching, records: data.pageRecords, totalRecords: data.pageRecordsCount, totalPages: data.totalPages, error }
}
export const useGetProductsByEntity = params => {
  let [isFetching, setFetching] = useState(true)
  let [data, setData] = useState(defaultParams)
  let [error, setError] = useState({ isError: false, message: '' })
  const payload = { ...params, entityName: 'Product' }
  useEffect(() => {
    let source = axios.CancelToken.source()
    if (isFetching) {
      search({ pageSize: 12, searchTerm: '', ...payload }, 'sku', source)
        .then(async data => {
          const products = await Promise.all(
            data.products.map(async product => {
              return fetch(`${process.env.REACT_APP_DELTA_STORE_URL}/public/ultracommerce/product/transform/byUrlTitle/${product.urlTitle}`)
                .then(d => d.json())
                .then(data => ({
                  ...data,
                  skuName: data.skus.find(sku => sku.skuID === data.defaultSku_skuID)?.skuName || data.productName,
                }))
            })
          )
          setData({
            ...data,
            products,
          })
          setFetching(false)
        })
        .catch(error => setError(error))
    }
    return () => {
      source.cancel()
    }
    // eslint-disable-next-line
  }, [isFetching])
  return { isFetching, records: data?.products, total: data?.resultCount, error }
}

export const useGetEntityByID = () => {
  let [request, setRequest] = useState({ isFetching: false, isLoaded: false, makeRequest: false, data: [], error: '', params: {}, entity: '' })
  useEffect(() => {
    let source = axios.CancelToken.source()
    if (request.makeRequest) {
      const payload = { ...request.params, entityName: request.entity }

      SlatwallApiService.general.getEntity(payload, headers, source).then(response => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
        if (response.isSuccess()) {
          setRequest({ data: response.success().data, isFetching: false, isLoaded: true, makeRequest: false, params: {} })
        } else {
          setRequest({ data: [], isFetching: false, makeRequest: false, isLoaded: true, params: {}, error: 'Something was wrong' })
        }
      })
    }
    return () => {
      source.cancel()
    }
  }, [request, setRequest])

  return [request, setRequest]
}

export const useGetProductDetails = () => {
  let [request, setRequest] = useState({ isFetching: false, isLoaded: false, makeRequest: false, data: {}, error: '', params: {} })
  useEffect(() => {
    let source = axios.CancelToken.source()
    if (request.makeRequest) {
      const payload = request.params
      SlatwallApiService.products.list(payload, headers, source).then(response => {
        if (response.isSuccess() && response.success().pageRecords && response.success().pageRecords.length) {
          setRequest({ data: response.success().pageRecords[0], isFetching: false, isLoaded: true, makeRequest: false, params: {} })
        } else {
          setRequest({ data: {}, isFetching: false, makeRequest: false, isLoaded: true, params: {}, error: 'Something was wrong' })
        }
      })
    }
    return () => {
      source.cancel()
    }
  }, [request, setRequest])

  return [request, setRequest]
}

export const useGetEntityByUrlTitleAdvanced = (urlTitle, params = {}) => {
  let [isFetching, setFetching] = useState(true)
  let [data, setData] = useState({ product: {}, totalRecords: 0, totalPages: 1 })
  let [error, setError] = useState({ isError: false, message: '' })

  useEffect(() => {
    let source = axios.CancelToken.source()
    setFetching(true)
    const payload = { urlTitle, entityName: 'sku', includeAttributesMetadata: true, includeCategories: true, includeOptions: true, includeSkus: false, includeSettings: true, ...params }
    fetchFromDelta(payload, headers, source).then(response => {
      if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
      if (response.isSuccess()) {
        let data = response.success().data
        data?.skus?.forEach(sku => {
          sku.slug = sku.options.map(opt => `${opt.optionGroupCode}=${opt.optionCode}`).join('&')
        })
        if (data?.defaultSku_skuID?.length && data?.skus?.length) {
          const defaultSku = data?.skus.filter(sku => sku.skuID === data?.defaultSku_skuID)
          if (defaultSku.length) {
            data.defaultSku_slug = defaultSku
              ?.at(0)
              .options.map(opt => `${opt.optionGroupCode}=${opt.optionCode}`)
              .join('&')
          }
        }
        setData(data)
      } else {
        setData([])
        setError({ isError: true, message: 'Something was wrong' })
      }
      setFetching(false)
    })

    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlTitle])
  return { isFetching, product: data, attributeSets: data.attributeSets, productOptions: data?.optionGroups, skus: data?.skus, productBundle: data?.productBundle, productBundleBuildOnAccount: data?.productBundleBuildOnAccount, skuAddons: data?.skuAddons, error }
}

export const useGetEntityByUrlTitleIncludeSkus = (urlTitle, params = {}) => {
  let [skuFetching, setFetching] = useState(true)
  let [data, setData] = useState({ product: {}, totalRecords: 0, totalPages: 1 })
  let [error, setError] = useState({ isError: false, message: '' })

  useEffect(() => {
    let source = axios.CancelToken.source()
    setFetching(true)
    const payload = { urlTitle, entityName: 'sku', includeSkus: true, ...params }
    fetchFromDelta(payload, headers, source).then(response => {
      if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
      if (response.isSuccess()) {
        let data = response.success().data
        data?.skus?.forEach(sku => {
          sku.slug = sku.options.map(opt => `${opt.optionGroupCode}=${opt.optionCode}`).join('&')
        })
        if (data?.defaultSku_skuID?.length && data?.skus?.length) {
          const defaultSku = data?.skus.filter(sku => sku.skuID === data?.defaultSku_skuID)
          if (defaultSku.length) {
            data.defaultSku_slug = defaultSku
              ?.at(0)
              .options.map(opt => `${opt.optionGroupCode}=${opt.optionCode}`)
              .join('&')
          }
        }
        setData(data)
      } else {
        setData([])
        setError({ isError: true, message: 'Something was wrong' })
      }
      setFetching(false)
    })

    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlTitle])
  return { skuFetching, product: data, attributeSets: data.attributeSets, productOptions: data?.optionGroups, skus: data?.skus, productBundle: data?.productBundle, productBundleBuildOnAccount: data?.productBundleBuildOnAccount, skuAddons: data?.skuAddons, error }
}

const defaultParams = { skus: [], potentialFilters: {}, total: 0, pageSize: 12, totalPages: 1 }

async function search(params, options = {}) {
  const payload = { ...params }
  if (payload.keyword) {
    payload['searchTerm'] = payload.keyword
  }

  if (payload.currentPage) {
    payload['pageFrom'] = payload.currentPage
  }

  delete payload['keyword']
  delete payload['currentPage']

  const searchData = {
    facets: [],
    filters: [],
    type: 'sku',
    query: payload.searchTerm,
    pageFrom: payload.pageFrom,
    sort: 'landrplus',
  }

  if (payload.hasOwnProperty('sort') && payload.sort) {
    searchData.sort = payload.sort
  }

  if (payload.hasOwnProperty('landrplus') && payload.landrplus) {
    searchData.facets.push({
      key: 'landrplus',
      value: 'Yes',
    })
  }

  if (payload.hasOwnProperty('f:productFeaturedFlag') && payload['f:productFeaturedFlag']) {
    searchData.filters.push({
      key: 'productFeaturedFlag',
      value: 'Yes',
    })
  }

  if (payload.hasOwnProperty('f:type') && payload['f:type']) {
    searchData.type = payload['f:type']
  }

  if (window.location.pathname.startsWith('/brand')) {
    searchData.facets.push({
      key: 'brandUrlTitle',
      value: window.location.pathname.split('/')[2],
    })
  } else if (window.location.pathname.startsWith('/products')) {
    searchData.facets.push({
      key: 'productTypeUrlTitle',
      value: window.location.pathname.split('/').pop(),
    })
    Object.keys(payload).forEach(key => {
      if (key.startsWith('facet_')) {
        let value = payload[key]
        if (value !== '') {
          searchData.facets.push({ key: key.replace('facet_', ''), value })
        }
      }
    })
  } else {
    Object.keys(payload).forEach(key => {
      if (key.startsWith('facet_')) {
        let value = payload[key]
        if (value !== '') {
          searchData.facets.push({ key: key.replace('facet_', ''), value })
        }
      }
    })
  }

  const { data } = await axios(`${process.env.REACT_APP_DELTA_API_URL}/api/v1/public/search`, {
    data: searchData,
    method: 'POST',
    cancelToken: options.source?.token,
  })

  return data
}

export const useGetProductsWithPagination = filters => {
  let [isFetching, setFetching] = useState(true)
  let [data, setData] = useState(defaultParams)
  let [error, setError] = useState({ isError: false, message: '' })

  useEffect(() => {
    let source = axios.CancelToken.source()
    const payload = JSON.parse(filters)
    setFetching(true)
    search({ pageSize: 12, searchTerm: '', ...payload }, 'sku', source)
      .then(data => {
        setData(data)
        setFetching(false)
      })
      .catch(error => setError(error))
  }, [filters])
  return {
    isFetching,
    records: data.skus,
    potentialFilters: data.potentialFilters,
    total: data.resultCount,
    totalPages: Math.ceil(data.resultCount / data.pageSize),
    error,
    sorting: data?.sortBy?.filter(sort => {
      return sort.slug !== 'landrplus'
    }),
  }
}

export const useGetSpecialProductsWithPagination = filters => {
  let [isFetching, setFetching] = useState(true)
  let [data, setData] = useState(defaultParams)
  let [error, setError] = useState({ isError: false, message: '' })

  useEffect(() => {
    let source = axios.CancelToken.source()
    const payload = JSON.parse(filters)
    setFetching(true)
    search({ pageSize: 12, searchTerm: '', ...payload }, 'sku', source)
      .then(data => {
        setData(data)
        setFetching(false)
      })
      .catch(error => setError(error))
  }, [filters])
  return {
    isFetching,
    records: data.skus,
    potentialFilters: data?.potentialFilters,
    total: data.resultCount,
    totalPages: Math.ceil(data.resultCount / data.pageSize),
    error,
    sorting: data?.sortBy,
  }
}

export const useGetProducts = params => {
  const propertyIdentifierList = useSelector(state => state.configuration.listings.productListing.params)
  const returnFacetList = useSelector(state => state.configuration.listings.productListing.returnFacetList)
  const returnFacetListWithFilter = useSelector(state => state.configuration.listings.productListing.returnFacetListWithFilter)
  let [request, setRequest] = useState({
    isFetching: false,
    isLoaded: false,
    makeRequest: false,
    params: {},
    filtering: {
      keyword: params.keyword,
      orderBy: params.orderBy,
    },
    data: {
      pageRecords: [],
      limitCountTotal: '',
      currentPage: '',
      pageRecordsCount: '',
      pageRecordsEnd: '',
      pageRecordsShow: '',
      pageRecordsStart: '',
      recordsCount: '',
      totalPages: '',
    },
  })
  if (!!params['facet_brandUrlTitle'] || !!params['facet_productTypeUrlTitle']) {
    params['returnFacetList'] = returnFacetListWithFilter // if hide we should correct
  } else {
    params['returnFacetList'] = returnFacetList
  }

  useEffect(() => {
    let source = axios.CancelToken.source()
    if (request.makeRequest) {
      const selectedLocale = { lang: localStorage.getItem('i18nextLng') }
      const payload = { ...propertyIdentifierList, ...request.params, ...selectedLocale }

      SlatwallApiService.products.search(payload, headers, source).then(response => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
        if (response.isSuccess()) {
          const { currentPage, pageSize, potentialFilters, total } = response.success().data
          const totalPages = Math.ceil(total / pageSize)
          const products = response.success().data.skus.map(sku => {
            return { ...sku, showInput: false, showInputLabel: false, salePrice: sku.skuPrice, productName: sku.product_productName, urlTitle: sku.productUrlTitle, productCode: sku.product_productCode, imageFile: sku.sku_imageFile, skuID: sku.sku_skuID, skuCode: sku.sku_skuCode }
          })

          setRequest({
            ...request,
            filtering: { ...potentialFilters },
            data: { ...request.data.data, currentPage, pageSize, recordsCount: total, totalPages, pageRecords: products },
            isFetching: false,
            isLoaded: true,
            makeRequest: false,
          })
        } else {
          setRequest({
            data: {
              pageRecords: [],
              limitCountTotal: '',
              currentPage: '',
              pageRecordsCount: '',
              pageRecordsEnd: '',
              pageRecordsShow: '',
              pageRecordsStart: '',
              recordsCount: '',
              totalPages: '',
            },
            isFetching: false,
            makeRequest: false,
            isLoaded: true,
            params: {},
            error: 'Something was wrong',
          })
        }
      })
    }
    return () => {
      source.cancel()
    }
  }, [request, setRequest, propertyIdentifierList])

  return [request, setRequest]
}

export const useGetAvailableShippingMethods = () => {
  let [request, setRequest] = useState({ isFetching: false, isLoaded: false, makeRequest: false, data: {}, error: '', params: {} })
  useEffect(() => {
    let source = axios.CancelToken.source()
    if (request.makeRequest) {
      const payload = request.params
      SlatwallApiService.cart.availableShippingMethods(payload, headers, source).then(response => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
        if (response.isSuccess() && response.success().pageRecords && response.success().pageRecords.length) {
          setRequest({ data: response.success().pageRecords[0], isFetching: false, isLoaded: true, makeRequest: false, params: {} })
        } else {
          setRequest({ data: {}, isFetching: false, makeRequest: false, isLoaded: true, params: {}, error: 'Something was wrong' })
        }
      })
    }
    return () => {
      source.cancel()
    }
  }, [request, setRequest])

  return [request, setRequest]
}

export const useGetAvailablePaymentMethods = () => {
  let [request, setRequest] = useState({ isFetching: false, isLoaded: false, makeRequest: false, data: {}, error: '', params: {} })
  useEffect(() => {
    let source = axios.CancelToken.source()
    if (request.makeRequest) {
      const payload = request.params
      SlatwallApiService.cart.availablePaymentMethods(payload, headers, source).then(response => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
        if (response.isSuccess() && response.success().pageRecords && response.success().pageRecords.length) {
          setRequest({ data: response.success().pageRecords[0], isFetching: false, isLoaded: true, makeRequest: false, params: {} })
        } else {
          setRequest({ data: {}, isFetching: false, makeRequest: false, isLoaded: true, params: {}, error: 'Something was wrong' })
        }
      })
    }
    return () => {
      source.cancel()
    }
  }, [request, setRequest])

  return [request, setRequest]
}

export const useAddWishlistItem = () => {
  let [request, setRequest] = useState({ isFetching: false, isLoaded: false, makeRequest: false, data: {}, error: '', params: {} })
  useEffect(() => {
    let source = axios.CancelToken.source()
    if (request.makeRequest) {
      const payload = request.params

      SlatwallApiService.orderTemplate.addWishlistItem(payload, headers, source).then(response => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
        if (response.isSuccess()) {
          setRequest({ data: response.success().pageRecords, isFetching: false, isLoaded: true, makeRequest: false, params: {} })
        } else {
          setRequest({ data: {}, isFetching: false, makeRequest: false, isLoaded: true, params: {}, error: 'Something was wrong' })
        }
      })
    }

    return () => {
      source.cancel()
    }
  }, [request, setRequest])

  return [request, setRequest]
}

export const useGetOrderDetails = () => {
  let [request, setRequest] = useState({ isFetching: false, isLoaded: false, makeRequest: false, data: {}, error: '', params: {} })
  useEffect(() => {
    let source = axios.CancelToken.source()
    if (request.makeRequest) {
      const payload = request.params

      SlatwallApiService.order.get(payload, headers, source).then(response => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
        if (response.isSuccess()) {
          setRequest({ data: response.success().orderDetails, isFetching: false, isLoaded: true, makeRequest: false, params: {} })
        } else {
          setRequest({ data: {}, isFetching: false, makeRequest: false, isLoaded: true, params: {}, error: 'Something was wrong' })
        }
      })
    }
    return () => {
      source.cancel()
    }
  }, [request, setRequest])

  return [request, setRequest]
}

export const useGetAllOrders = () => {
  let [request, setRequest] = useState({ isFetching: false, isLoaded: false, makeRequest: false, data: [], error: '', params: {} })
  useEffect(() => {
    let source = axios.CancelToken.source()
    if (request.makeRequest) {
      const payload = request.params

      SlatwallApiService.account.orders(payload, headers, source).then(response => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
        if (response.isSuccess()) {
          setRequest({ data: response.success().ordersOnAccount, isFetching: false, isLoaded: true, makeRequest: false, params: {} })
        } else {
          setRequest({ data: {}, isFetching: false, makeRequest: false, isLoaded: true, params: {}, error: 'Something was wrong' })
        }
      })
    }
    return () => {
      source.cancel()
    }
  }, [request, setRequest])

  return [request, setRequest]
}

export const useGetAccountCartsAndQuotes = () => {
  let [request, setRequest] = useState({ isFetching: false, isLoaded: false, makeRequest: false, data: [], error: '', params: {} })
  useEffect(() => {
    let source = axios.CancelToken.source()
    if (request.makeRequest) {
      const payload = request.params

      SlatwallApiService.account.cartsAndQuotes(payload, headers, source).then(response => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
        if (response.isSuccess()) {
          setRequest({ data: response.success().cartsAndQuotesOnAccount.ordersOnAccount, isFetching: false, isLoaded: true, makeRequest: false, params: {} })
        } else {
          setRequest({ data: {}, isFetching: false, makeRequest: false, isLoaded: true, params: {}, error: 'Something was wrong' })
        }
      })
    }
    return () => {
      source.cancel()
    }
  }, [request, setRequest])

  return [request, setRequest]
}

export const useResizedImageByProfileName = () => {
  let [request, setRequest] = useState({ isFetching: false, isLoaded: false, makeRequest: false, data: [], error: '', params: { profileName: 'listing', skuIDs: '' } })
  useEffect(() => {
    let source = axios.CancelToken.source()
    if (request.makeRequest) {
      const payload = request.params

      SlatwallApiService.products.getImagePaths(payload, headers, source).then(response => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
        if (response.isSuccess()) {
          setRequest({ data: response.success().resizedImagePaths, isFetching: false, isLoaded: true, makeRequest: false, params: {} })
        } else {
          setRequest({ data: {}, isFetching: false, makeRequest: false, isLoaded: true, params: {}, error: 'Something was wrong' })
        }
      })
    }
    return () => {
      source.cancel()
    }
  }, [request, setRequest])

  return [request, setRequest]
}

export const useAddOrderShippingAddress = () => {
  let [request, setRequest] = useState({ isFetching: false, isLoaded: false, makeRequest: false, data: {}, error: '', params: {} })
  useEffect(() => {
    let source = axios.CancelToken.source()
    if (request.makeRequest) {
      const payload = request.params

      SlatwallApiService.cart.addShippingAddress(payload, headers, source).then(response => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
        if (response.isSuccess()) {
          setRequest({ data: response.success(), isFetching: false, isLoaded: true, makeRequest: false, params: {} })
        } else {
          setRequest({ data: {}, isFetching: false, makeRequest: false, isLoaded: true, params: {}, error: 'Something was wrong' })
        }
      })
    }
    return () => {
      source.cancel()
    }
  }, [request, setRequest])

  return [request, setRequest]
}

export const useGetProductAvailableSkuOptions = () => {
  let [request, setRequest] = useState({ isFetching: false, isLoaded: false, makeRequest: false, data: { sku: {} }, error: '', params: {} })
  const loc = useLocation()
  const history = useHistory()
  useEffect(() => {
    let source = axios.CancelToken.source()
    if (request.makeRequest) {
      const payload = request.params

      SlatwallApiService.products.getDetails(payload, headers, source).then(response => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
        if (response.isSuccess()) {
          setRequest({ data: response.success(), isFetching: false, isLoaded: true, makeRequest: false, params: {} })
        } else {
          setRequest({ data: { sku: {} }, isFetching: false, makeRequest: false, isLoaded: true, params: {}, error: 'Something was wrong' })
        }
      })
    }
    return () => {
      source.cancel()
    }
  }, [request, setRequest, loc, history])

  return [request, setRequest]
}

export const useGetProductSkuSelected = () => {
  let [request, setRequest] = useState({ isFetching: false, isLoaded: false, makeRequest: false, data: {}, error: '', params: {} })
  useEffect(() => {
    let source = axios.CancelToken.source()
    if (request.makeRequest) {
      const payload = request.params

      SlatwallApiService.products.getSkuSelected(payload, headers, source).then(response => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
        if (response.isSuccess()) {
          setRequest({ data: response.success(), isFetching: false, isLoaded: true, makeRequest: false, params: {} })
        } else {
          setRequest({ data: {}, isFetching: false, makeRequest: false, isLoaded: true, params: {}, error: 'Something was wrong' })
        }
      })
    }
    return () => {
      source.cancel()
    }
  }, [request, setRequest])

  return [request, setRequest]
}

export const useGetSkuOptionDetails = () => {
  let [request, setRequest] = useState({ isFetching: false, isLoaded: false, makeRequest: false, data: [], error: '', params: {} })
  useEffect(() => {
    let source = axios.CancelToken.source()
    if (request.makeRequest) {
      const payload = request.params

      SlatwallApiService.products.getSkuOptionDetails(payload, headers, source).then(response => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
        if (response.isSuccess()) {
          const filterdOptions = Object.keys(response.success().skuOptionDetails)
            .map(key => {
              return response.success().skuOptionDetails[key]
            })
            .sort((a, b) => a.sortOrder - b.sortOrder)
          setRequest({ data: filterdOptions, isFetching: false, isLoaded: true, makeRequest: false, params: {} })
        } else {
          setRequest({ data: [], isFetching: false, makeRequest: false, isLoaded: true, params: {}, error: 'Something was wrong' })
        }
      })
    }
    return () => {
      source.cancel()
    }
  }, [request, setRequest])

  return [request, setRequest]
}

export const useGetProductImageGallery = (urlTitle, skuID, imageFile) => {
  let [isFetching, setFetching] = useState(true)
  let [imageGallery, setImageGallery] = useState([])
  let [error, setError] = useState({ isError: false, message: '' })
  const [sliders, setSliders] = useState({
    nav1: null,
    nav2: null,
  })
  const slider1 = useRef()
  const slider2 = useRef()
  const selectedSkuId = skuID;

  useEffect(() => {
    setSliders({
      nav1: slider1.current,
      nav2: slider2.current,
    })
    let source = axios.CancelToken.source()
    if (process.env.REACT_APP_DELTA_STORE_URL) {
      fetch(`${process.env.REACT_APP_DELTA_STORE_URL}/public/ultracommerce/product/transform/image-gallery/byUrlTitle/${urlTitle}/image-gallery`)
        .then(resp => resp.json())
        .then(data => {
          setImageGallery(data.images)
          setFetching(false)
        })
        .catch(e => {
          setError({ isError: true, message: e.message })
          setFetching(false)
        })
    } else {
      SlatwallApiService.products.getGallery({ urlTitle, resizeSizes: 'large,small' }, headers, source).then(response => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) setError({ isError: false, message: getErrorMessage(response.success().errors) })
        if (response.isSuccess()) {
          setImageGallery(response.success().images)
        } else {
          setImageGallery({})
        }
        setFetching(false)
      })
    }
    
    return () => {
      source.cancel()
    }
  }, [urlTitle])

  let filterImages = [];
  if (imageGallery.length > 0) {
    filterImages = imageGallery?.filter(({ skuID, type }) => {
        return type === 'skuDefaultImage' || type === 'productAlternateImage' || skuID !== selectedSkuId
      })
      .filter(({ resizedImagePaths = [] }) => {
        return resizedImagePaths[0] && !resizedImagePaths[0].includes('missingimage')
      })
  }
  if (filterImages?.length === 0) {
    filterImages = [{ ORIGINALPATH: '', NAME: '', resizedImagePaths: ['', '', ''] }]
  }
  filterImages?.unshift(
    filterImages.splice(
      filterImages.findIndex(item => item.originalFilename === imageFile),
      1
    )[0]
  )
  filterImages = filterImages?.reverse()
  return { isFetching, sliders, slider1, slider2, filterImages, error }
}

export const useGetProductsByEntityModified = () => {
  let [request, setRequest] = useState({ isFetching: false, isLoaded: false, makeRequest: false, data: [], error: '', params: {}, entity: '' })
  useEffect(() => {
    let source = axios.CancelToken.source()
    if (request.makeRequest) {
      const payload = { ...request.params, entityName: 'Product', includeAttributesMetadata: true }

      SlatwallApiService.general.getEntity(payload, headers, source).then(response => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) toast.error(getErrorMessage(response.success().errors))
        if (response.isSuccess()) {
          const products = response.success().data.pageRecords.map(product => {
            return { ...product, showInput: false, showInputLabel: false, brandName: product.brand_brandName, brandUrlTitle: product.brand_urlTitle, imageFile: product.defaultSku_imageFile, skuCode: product.defaultSku_skuCode, product: product.defaultSku_imageFile, skuID: product.defaultSku_skuID }
          })
          setRequest({ data: products, attributeSets: response.success().attributeSets, isFetching: false, isLoaded: true, makeRequest: false, params: {} })
        } else {
          setRequest({ data: [], attributeSets: [], isFetching: false, makeRequest: false, isLoaded: true, params: {}, error: 'Something was wrong' })
        }
        return response
      })
    }
    return () => {
      source.cancel()
    }
  }, [request, setRequest])

  return [request, setRequest]
}
