import { useUtilities } from '@ten24/slatwallreactlibrary/hooks'
import { useTranslation } from 'react-i18next'

const SWForm = ({ formik, children, title = '', subTitle = '', primaryButtontext = '' }) => {
  let { eventHandlerForWSIWYG } = useUtilities()
  const { t } = useTranslation()

  return (
    <>
      <h2 className="h4 mb-1">{title}</h2>
      <p
        className="font-size-sm text-muted mb-4"
        onClick={eventHandlerForWSIWYG}
        dangerouslySetInnerHTML={{
          __html: subTitle,
        }}
      />
      <form onSubmit={formik.handleSubmit}>
        {children}
        <hr className="mt-4" />
        <div className="d-flex pt-4 justify-content-between">
          <a className="btn btn-secondary" href={`${process.env.REACT_APP_HOST_URL}/custom/assets/Files/creditapplication.pdf`} target="_blank" rel="noreferrer noopenner">
            {t('frontend.account.downloadCreditApplication')}
          </a>
          <button className="btn btn-primary" type="submit">
            {primaryButtontext}
          </button>
        </div>
      </form>
    </>
  )
}

const SWInput = ({ required = false, formik, token = '', label = '', wrapperClasses = 'row', type = 'text', showErrors = true }) => {
  return (
    <div className={wrapperClasses}>
      <div className="col form-group">
        <label className="control-label" htmlFor={token}>
          {label}
        </label>
        <input className="form-control" type={type} id={token} value={formik.values[token]} onChange={formik.handleChange} onBlur={formik.handleBlur} />
        {showErrors && formik.touched[token] && formik.errors[token] && <span className="form-error-msg">{formik.errors[token]}</span>}
      </div>
    </div>
  )
}

export { SWForm, SWInput }
