import landrsalesplus from '../../assets/images/landrsalesplus.png'

const VerifiedButton = ({ landrplus, className = 'btn-verified' }) => {
  if (landrplus === true || landrplus === 'true' || landrplus === 'Yes') {
    return (
      <span className={className} type="button" data-toggle="tooltip" data-placement="right" title="L&R Plus+">
        <img src={landrsalesplus} alt="L&R Plus+" className="landrsalesplus-icon" />
      </span>
    )
  } else {
    return null
  }
}
export { VerifiedButton }
