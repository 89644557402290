import Slider from 'react-slick'
import { useGetProductImageGallery } from '../../hooks/useAPI'
import { useEffect, useState } from 'react'
import defaultMissing from '../../assets/images/missing.png'
import defaultMissingBrand from '../../assets/images/missing-brand.png'
import defaultMissingProductType from '../../assets/images/missing-product-type.png'
import defaultMissingProduct from '../../assets/images/missing-product.png'

const DefaultImage = ({ alt = '', style, type, className = '' }) => {
  if (type === 'product') {
    return <img className={className} style={style} src={defaultMissingProduct} alt={alt} />
  } else if (type === 'productType') {
    return <img className={className} style={style} src={defaultMissingProductType} alt={alt} />
  } else if (type === 'brand') {
    return <img className={className} style={style} src={defaultMissingBrand} alt={alt} />
  }
  return <img className={className} style={style} src={defaultMissing} alt={alt} />
}

const SWImage = ({ className = '', customPath, src, alt = '', style = {}, type = 'product', fallbackPath = '' }) => {
  const [retryCount, setRetryCount] = useState(0)
  const [classList, setClassList] = useState(['productImage'])

  const onErrorCallback = e => {
    e.preventDefault()
    e.target.onerror = null
    const localRetryCount = retryCount + 1
    const showFallback = fallbackPath.length && localRetryCount === 1 ? 'fallbackImage' : ''
    const showDefault = (fallbackPath.length && localRetryCount > 1) || (!fallbackPath.length && localRetryCount === 1) ? 'defaultImage' : ''
    setRetryCount(localRetryCount)
    setClassList(['productImage', className, showFallback, showDefault])
    if (type === 'product') {
      e.target.src = showFallback ? `${fallbackPath}` : defaultMissingProduct
    } else if (type === 'productType') {
      e.target.src = defaultMissingProductType
    } else if (type === 'brand') {
      e.target.src = defaultMissingBrand
    } else {
      e.target.src = defaultMissing
    }
  }

  if (src) {
    return <img className={classList.join(' ')} src={src} alt={alt} style={style} onError={onErrorCallback} />
  }
  return <DefaultImage className={`${className} defaultImage`} style={style} type={type} />
}
/*

Probably should move to this eventually 
https://react-slick.neostack.com/docs/example/custom-paging
*/
const ProductDetailGallery = ({ urlTitle, skuID, imageFile }) => {
  const { filterImages, sliders, slider1, slider2 } = useGetProductImageGallery(urlTitle, skuID, imageFile)
  const defaultImage = filterImages.find(filter => filter?.skuID === skuID)?.resizedImagePaths?.at(0)

  const fallbackImage = filterImages.find(filter => filter?.originalFilename === imageFile)?.resizedImagePaths?.at(0)

  const [sliderDefaultImage, setSliderDefaultImage] = useState(fallbackImage)
  useEffect(() => {
    if (fallbackImage) {
      setSliderDefaultImage(fallbackImage)
    } else {
      setSliderDefaultImage(defaultImage)
    }
  }, [defaultImage, fallbackImage])

  return (
    <div className="col-lg-6 pr-lg-5 pt-0">
      <div className="cz-product-gallery">
        <div className="cz-preview order-sm-2">
          <div className="cz-preview-item active" id="primaryProductImage">
            <div>
              <Slider arrows={false} asNavFor={sliders.nav2} ref={slider => (slider1.current = slider)}>
                {(fallbackImage || defaultImage) && <SWImage key="default_image" customPath="/" src={sliderDefaultImage} className="cz-image-zoom w-100 mx-auto" alt="Product" style={{ maxWidth: '500px' }} />}
                {!defaultImage &&
                  filterImages?.map(({ resizedImagePaths, originalFilename }, index) => {
                    return <SWImage key={`${originalFilename}-${index}`} customPath="/" src={resizedImagePaths[0]} className="cz-image-zoom w-100 mx-auto" alt="Product" style={{ maxWidth: '500px' }} />
                  })}
              </Slider>
            </div>
            <div className="cz-image-zoom-pane"></div>
          </div>
        </div>
      </div>
      <div className="cz-product-gallery">
        <div className="cz-preview order-sm-2">
          <div className="cz-preview-item active" id="primaryProductImageRow">
            <div>
              {!!filterImages && filterImages.length > 1 && (
                <Slider arrows={false} infinite={filterImages.length > 3} asNavFor={sliders.nav1} ref={slider => (slider2.current = slider)} slidesToShow={3} swipeToSlide={true} focusOnSelect={true}>
                  {filterImages
                    .filter(images => images.skuID !== skuID)
                    .map(({ resizedImagePaths, originalFilename }, index) => {
                      return <SWImage key={`${originalFilename}-${index}`} customPath="/" src={resizedImagePaths[0]} className="cz-image-zoom w-100 mx-auto" alt="Product" style={{ maxWidth: '100px' }} />
                    })}
                </Slider>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export { ProductDetailGallery }
