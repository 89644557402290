import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ListingGrid } from '../../components/Listing/ListingGrid'
import { ListingSidebar } from '../../components/Listing/ListingSidebar'
import { useUtilities } from '@ten24/slatwallreactlibrary/hooks'
import { useSpecialListing } from '../../hooks/useSpecialListing'
import { ListingPagination } from '@ten24/slatwallreactlibrary/components'

const Specials = ({ preFilter, hide = [] }) => {
  let loc = useLocation()
  const content = useSelector(state => state.content[loc.pathname.substring(1)])
  const { title, customBody } = content || {}
  let { eventHandlerForWSIWYG } = useUtilities()
  const { records, isFetching, potentialFilters, total, totalPages, updateAttribute, setPage, setKeyword, params } = useSpecialListing(preFilter)

  return (
    <div className="p-0">
      <div className="page-title-overlap bg-lightgray pt-4">
        <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
          <div className="order-lg-1 pr-lg-4 text-center">
            <h1 className="h3 text-dark mb-0 font-accent">{title || ''}</h1>
          </div>
        </div>
      </div>
      <div className="container bg-light box-shadow-lg rounded-lg p-5">
        <div
          className="content-body mb-5"
          onClick={eventHandlerForWSIWYG}
          dangerouslySetInnerHTML={{
            __html: customBody || '',
          }}
        />
        <div className="row">
          <aside className="col-lg-4">
            <ListingSidebar isFetching={isFetching} hide={hide} filtering={potentialFilters} recordsCount={total} keyword={params['keyword']} setKeyword={setKeyword} updateAttribute={updateAttribute} />
          </aside>
          <div className="col-lg-8">
            <ListingGrid isFetching={isFetching} pageRecords={records} />
            <ListingPagination recordsCount={total} currentPage={params['currentPage']} totalPages={totalPages} setPage={setPage} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Specials
