const TermPaymentDetails = ({ termPayment }) => {
  const { paymentMethod } = termPayment

  return (
    <>
      <p>
        <em>{paymentMethod?.paymentMethodName}</em>
      </p>
      <p># {termPayment?.purchaseOrderNumber}</p>
    </>
  )
}

export { TermPaymentDetails }