import { Link } from 'react-router-dom'
import { AccountContent } from '@ten24/slatwallreactlibrary/components'
import { AccountLayout } from '../'
import { useAccountProfile } from '@ten24/slatwallreactlibrary/hooks/'
import { isImpersonating } from '@ten24/slatwallreactlibrary/utils'
import { useTranslation } from 'react-i18next'

const AccountProfile = ({ crumbs, title, customBody, contentTitle }) => {
  const { t } = useTranslation()
  const { formik } = useAccountProfile()
  
  return (
    <AccountLayout crumbs={crumbs} title={title}>
      <AccountContent customBody={customBody} contentTitle={contentTitle} />
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-sm-12">
            <h4 className="text-left my-lg-4">Profile Info</h4>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="accountFirstName">{t('frontend.account.first_name')}</label>
              <input className="form-control" type="text" id="accountFirstName" value={formik.values.accountFirstName} onChange={formik.handleChange} />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="accountLastName">{t('frontend.account.last_name')}</label>
              <input className="form-control" type="text" id="accountLastName" value={formik.values.accountLastName} onChange={formik.handleChange} />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="accountEmailAddress">{t('frontend.account.email')}</label>
              <input className="form-control" type="accountEmailAddress" id="accountEmailAddress" value={formik.values.accountEmailAddress} onChange={formik.handleChange} disabled="" />
            </div>
          </div>
          {/* <div className="col-sm-4">
            <div className="form-group">
              <label htmlFor="accountPhoneNumber">Phone Number</label>
              <input className="form-control" type="text" id="accountPhoneNumber" value={formik.values.accountPhoneNumber} onChange={formik.handleChange} required="" />
            </div>
          </div>
          <div className="col-sm-2">
            <div className="form-group">
              <label htmlFor="accountExt">Ext.</label>
              <input className="form-control" type="text" id="accountExt" value={formik.values.accountExt} onChange={formik.handleChange} required="" />
            </div>
          </div> */}
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="accountCompany">{t('frontend.account.company')}</label>
              <input className="form-control" value={formik.values.accountCompany} type="text" onChange={formik.handleChange} id="accountCompany" />
            </div>
          </div>
          <div className="col-12">
            <hr className="mt-2 mb-3" />
            <div className="d-flex flex-wrap justify-content-end">
            {!isImpersonating() &&
              <Link className="btn btn-secondary mt-3 mt-sm-0 mr-3" to="/my-account/updatePassword">
                {t('frontend.account.password_update')}
              </Link>}
              <button type="submit" disabled={formik.isSubmitting} className="btn btn-primary mt-3 mt-sm-0">
                {t('frontend.account.profile_update')}
              </button>
            </div>
          </div>
        </div>
      </form>
    </AccountLayout>
  )
}

export { AccountProfile }
