import { useTranslation } from 'react-i18next'
import ContentLoader from 'react-content-loader'
import { useEffect, useMemo, useRef } from 'react'
import { FacetFilter } from './Filters/FacetFilter'
import { useLocation } from 'react-router'
import queryString from 'query-string'

const FilterLoader = props => (
  <ContentLoader speed={2} width={400} height={150} viewBox="0 0 400 200" backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...props}>
    <rect x="25" y="15" rx="5" ry="5" width="350" height="20" />
    <rect x="25" y="45" rx="5" ry="5" width="350" height="10" />
    <rect x="25" y="60" rx="5" ry="5" width="350" height="10" />
    <rect x="26" y="75" rx="5" ry="5" width="350" height="10" />
    <rect x="27" y="107" rx="5" ry="5" width="350" height="20" />
    <rect x="26" y="135" rx="5" ry="5" width="350" height="10" />
    <rect x="26" y="150" rx="5" ry="5" width="350" height="10" />
    <rect x="27" y="165" rx="5" ry="5" width="350" height="10" />
  </ContentLoader>
)

const ListingSidebar = ({ isFetching, hide, filtering, keyword, recordsCount, setKeyword, updateAttribute }) => {
  const { t } = useTranslation()
  const textInput = useRef(null)
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      const id = location.hash.replace('#', '')
      const element = document.getElementById(id)
      if (!isFetching && element) {
        element.scrollIntoView({ block: 'center', behavior: 'smooth' })
      }
    }, 100)
  }, [isFetching, location])

  const hiddenFacets = location.pathname === '/specials' ? ['landrplus'] : []
  const params = queryString.parse(location.search, { arrayFormat: 'separator', arrayFormatSeparator: ',' })
  const isDefaultFacet = (params['facet_brandUrlTitle'] !== '' || params['facet_productTypeUrlTitle'] !== '') && params['facet_brandUrlTitle'] !== undefined ? true : false
  let filters = useMemo(
    () =>
      Object.entries(filtering)
        .map(([type, filter]) => filter)
        .filter(filter => filter.options.length && !hiddenFacets.includes(filter.slug))
        .sort((a, b) => a.sortOrder - b.sortOrder),
    // eslint-disable-next-line
    [filtering, hide]
  )
  if (!isDefaultFacet) {
    filters = filters.filter((item) => {
      return ['productTypeUrlTitle', 'brandUrlTitle','landrplus', 'productCollection'].includes(item.slug)
    })
  }

  return (
    <div className="cz-sidebar rounded-lg box-shadow-lg" id="shop-sidebar">
      <div className="cz-sidebar-header box-shadow-sm">
        <button className="close ml-auto" type="button" data-toggle="collapse" data-target="#shop-sidebar" aria-expanded="false">
          <span className="d-inline-block font-size-xs font-weight-normal align-middle">{t('frontend.core.close_sidebar')} </span>
          <span className="d-inline-block align-middle ml-2" aria-hidden="true">
            <i className="far fa-times"></i>
          </span>
        </button>
      </div>
      <div className="cz-sidebar-body">
        <div className="widget widget-categories mb-3">
          <div className="row">
            <h3 className="widget-title col">{t('frontend.core.filters')}</h3>
            {recordsCount && <span className="text-right col">{`${recordsCount} ${t('frontend.core.results')}`}</span>}
          </div>
          <div className="input-group-overlay input-group-sm mb-2">
            <input
              className="cz-filter-search form-control form-control-sm appended-form-control"
              type="text"
              key={keyword}
              defaultValue={keyword}
              ref={textInput}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  setKeyword(e.target.value)
                }
              }}
              placeholder={t('frontend.plp.search.placeholder')}
            />
            <div className="input-group-append-overlay">
              <span className="input-group-text">
                <i
                  className="fa fa-search"
                  onClick={e => {
                    e.preventDefault()
                    setKeyword(textInput.current.value)
                  }}
                />
              </span>
            </div>
          </div>
          <div className="accordion mt-3 border-top" id="shop-categories">
            {isFetching && (
              <>
                <FilterLoader />
                <FilterLoader />
                <FilterLoader />
              </>
            )}
            {!isFetching &&
              filters.map(filter => (
                <div key={filter.slug}>
                  <FacetFilter filter={filter} facetIdentifier="slug" facetKey={`facet_${filter.slug}`} key={`facet_${filter.slug}`} updateAttribute={updateAttribute} />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export { ListingSidebar }
